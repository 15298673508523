<template>
  <div id="parameters" :class="{ hidden:currentParameter!=null }">
    <div class="content main box">

      <div class="page-tools">
        <button @click="createNew()" class="btn btn-primary">
          <i class="fal fa-plus"></i> aggiungi
        </button>
      </div>
      <div class="table-wrapper">
        <table class="items-list table table-hover table-striped table-autofit">
          <thead>
          <tr>
            <th>nome</th>
            <th>valore</th>
            <th>descrizione</th>
            <th colspan="2">azioni</th>
          </tr>
          </thead>
          <tbody>
          <tr :key="parameter.id" v-for="parameter in oldParameters" v-on:click="setCurrentParameter(parameter)">
            <td>{{ parameter.name }}</td>
            <td>{{ parameter.value }}</td>
            <td>{{ parameter.description }}</td>
            <td @click="setCurrentParameter(parameter)"><i class="fal fa-pencil"></i></td>
            <td><i class="fal fa-trash-alt"></i></td>
          </tr>
          </tbody>
          <tfoot></tfoot>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapState, mapMutations} from "vuex";

export default {
  name: "ParametersList",
  data() {
    return {
      currentParameter: null,
      oldParameters: [],
      stateClass: 'hidden'
    }
  },

  created() {
    if (localStorage.getItem('parameters') != null ) {
      this.oldParameters = JSON.parse(localStorage.getItem('parameters'));
    }
    this.getParameters({active:1})
        .then(
            () => {
              //console.log(this.parameters);
              localStorage.setItem('parameters',JSON.stringify(this.parameters));
              this.oldParameters = this.parameters;
            }
        )
        .catch(

        );
  },

  mounted() {
    if (localStorage.getItem('parameters') != null ) {
      this.oldParameters = JSON.parse(localStorage.getItem('parameters'));
    }
    this.getParameters({})
        .then(
            () => {
              //console.log(this.parameters);
              localStorage.setItem('parameters',JSON.stringify(this.parameters));
              this.oldParameters = this.parameters;
            }
        )
        .catch(

        );
  },

  methods: {
    ...mapActions({
      getParameters: 'parameter/getParameters'
    }),
    ...mapMutations('core', ['setCurrentParameter','setCurrentLocation']),

    createNew() {
      this.setCurrentParameter({name:'', color:''});
    }

  },

  watch: {
    currentLocation: function(val) {
      this.stateClass = val == null? 'hidden':''
    }
  },

  computed: {
    ...mapState('parameter', ['parameters']),
    ...mapState('core', ['currentUser','currentParameter','currentLocation'])
  }

}
</script>

<style scoped>

</style>