<template>
  <div>
    <Navigation></Navigation>
    <CustomersList></CustomersList>
    <CustomersForm></CustomersForm>
  </div>
</template>

<script>
import CustomersList from "../components/CustomersList";
import CustomersForm from "../components/CustomersForm";
import Navigation from "../components/Navigation";

export default {
  name: "Customers",
  components: {
    CustomersList,
    CustomersForm,
    Navigation
  }
}
</script>

<style scoped>

</style>