<template>
  <div>
    <Navigation></Navigation>
    <PrintersList></PrintersList>
    <PrintersForm></PrintersForm>
  </div>
</template>

<script>
import PrintersList from "../components/PrintersList";
import PrintersForm from "../components/PrintersForm";
import Navigation from "../components/Navigation";

export default {
  name: "Printers",
  components: {
    PrintersList,
    PrintersForm,
    Navigation
  }
}
</script>

<style scoped>

</style>