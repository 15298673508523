<template>
  <div class="employees-cost card dashboard-card">
    <h3>Costo collaboratori odierno</h3>
    <p v-if="totalCost <= 0"><i class="fal fa-spinner fa-pulse"></i></p>
    <h2 v-else="totalCost <= 0">€ {{ totalCost.toFixed(2) }}</h2>
    <span v-for="day in weekCost">€ {{ (day??0).toFixed(2) }} - </span>
    <div class="week ct-chart" style="width:50vw; height: 25vw;">

    </div>
    <!--ul>
      <li v-for="employee in employeeWithHours">
        {{ employee.nickname }}
        <ul>
          <li v-for="shift in employee.employeeHours">
            {{ shift.starTime }}
          </li>
        </ul>
      </li>
    </ul-->
  </div>
</template>

<script>
import {mapActions, mapState, mapMutations} from "vuex";
import warehouse from "@/views/Warehouse.vue";

export default {
  name: "EmployeesCost",
  data() {
    return {
      refreshId: null,
      employeeWithHours: [],
      totalCost: 0,
      weekCost: [],
      startDate: new Date(Date.parse((new Date(Date.now() - (168+15)*3600*1000)).toISOString().split('T')[0]+"T04:00:00.000z")),
      endDate: new Date((new Date(Date.parse((new Date(Date.now() - 15*3600*1000)).toISOString().split('T')[0]+"T04:00:00.000z"))).getTime() + 24*3600*1000),
      showInactive: false,
      stateClass: 'hidden'
    }
  },

  created() {
  },

  mounted() {
    this.loadHours();
    this.refreshId = setInterval(this.loadHours, 60000);
  },

  methods: {
    ...mapActions({
      getHours: 'employee/getHours'
    }),
    ...mapMutations('core', ['setCurrentEmployee','setCurrentLocation']),

    loadHours() {
      let payload = {
        startTime: this.startDate,
        endTime: this.endDate
      };

      this.getHours(payload)
        .then(
          (data) => {
            //console.log(data);
            localStorage.setItem('employeeHours',JSON.stringify(this.employees));
            this.employeeWithHours = data;
            let totalCost = 0;
            let startTime = this.startDate;
            let labels = [];
            let weekCost = [];
            while (startTime < this.endDate) {
              totalCost = 0;
              // console.log(startTime);
              data.forEach(employee => {
                employee.employeeHours.filter((shift) => Date.parse(shift.startTime) > startTime.getTime() && Date.parse(shift.startTime) <= startTime.getTime()+24*3600000 ).forEach(shift => {
                  let start = Date.parse(shift.startTime);
                  let end = Date.parse(shift.endTime);
                  if (!end)
                    end = new Date(Date.now()+3600*1000);
                  let hours = (end - start) / 3600000;
                  totalCost += employee.hourCost * hours;
                  //console.log(employee.firstName + " " + hours + " x " + employee.hourCost + " = " + employee.hourCost * hours);
                });
              });
              this.totalCost = totalCost;
              labels.push(startTime.toLocaleDateString('it-IT', { weekday: 'long' }));
              weekCost.push(totalCost>0?totalCost:null);
              startTime = new Date(startTime.getTime() + 24 * 3600 * 1000);
            }
            this.weekCost = weekCost;


            new Chartist.Line('.week.ct-chart', {
              labels: labels,
              series: [
                weekCost
              ]
            }, {
              fullWidth: true,
              chartPadding: {
                right: 40
              }
            });

          }
        )
        .catch(

        );
    }


  },

  computed: {
    ...mapState('employee', ['employees']),
    ...mapState('core', ['currentUser','currentEmployee','currentLocation'])
  }

}
</script>

<style scoped>

</style>