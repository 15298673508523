<template>
  <div id="products">
    <div class="content main box">

      <div class="page-tools container">
        <div class="row">
          <button @click="createNew()" class="btn btn-primary col-md-1">
            <i class="fal fa-plus"></i> aggiungi
          </button>
          <div class="col-md-1"></div>
          <button @click="setShowInactive(!showInactive)" class="btn btn-secondary col-md-1">
            <i class="fal" :class="showInactive?'fa-eye':'fa-eye-slash'"></i> inattivi
          </button>
          <div class="col-md-1"></div>
          <label for="typeId" class="col-md-1">Tipo</label>
          <select v-model="typeId" id="typeId" @change="typeChange" class="col-md-2">
            <option :selected="typeId==1" value="1">materie prime</option>
            <option :selected="typeId==2" value="2">semilavorati</option>
            <option :selected="typeId==3" value="3">prodotti finiti</option>
            <option :selected="typeId==5" value="5">variazioni</option>
            <option :selected="typeId==6" value="6">cliente no vendita</option>
            <option :selected="typeId==8" value="8">materiale di consumo</option>
          </select>
          <div class="col-md-1"></div>
          <label for="typeId" class="col-md-1">Categoria</label>
          <select v-model="categoryId" id="typeId" @change="categoryChange" class="col-md-2">
            <option value="">--tutte--</option>
            <option :selected="product?.category?.id == category.id" :value="category.id" v-for="category in categories">{{ category.name }}</option>
          </select>
        </div>
      </div>
      <div class="table-wrapper">
        <table class="items-list table table-hover table-striped table-autofit">
          <thead>
          <tr>
            <th>id</th>
            <th>name</th>
            <th>categoria</th>
            <th>u.m.</th>
            <th>costo unità</th>
            <th v-if="typeId == 3 || typeId == 5">prezzo</th>
            <th v-if="typeId == 3 || typeId == 5">margine</th>
            <th>attivo</th>
            <th :class="{hidden: typeId!=3 && typeId!=5 }">color</th>
            <th colspan="2">azioni</th>
          </tr>
          </thead>
          <tbody>
          <tr :class="product.active?'':'inactive'" :key="product.id" v-for="product in oldProducts.sort((a,b) => {
              if (typeId == 3) {
                let marginA = ((a.prices && a.prices.length > 0 )?   (a.prices.sort((da,db)=> da.dateFrom > db.dateFrom? -1:1 ))[0].price : 0) - a?.unitCost;
                let marginB = ((b.prices && b.prices.length > 0 )?   (b.prices.sort((da,db)=> da.dateFrom > db.dateFrom? -1:1 ))[0].price : 0) - b?.unitCost;
                return (marginA > marginB ? -1:1);
              }
              else {
                return a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1;
              }
           })">
            <td class="id-column">{{ product.id }}</td>
            <td>{{ product?.name }}</td>
            <td>{{ product?.category?.name }}</td>
            <td>{{ product?.unit?.measure }}</td>
            <td>{{ ('' + product?.unitCost.toFixed(2)).replace('.',',') }} €</td>
            <td v-if="typeId == 3 || typeId == 5">{{ (product.prices && product.prices.length > 0 )?   (product.prices.sort((a,b)=> a.dateFrom > b.dateFrom? -1:1 ))[0].price : 0 }}€</td>
            <td v-if="typeId == 3 || typeId == 5">{{ (((product.prices && product.prices.length > 0 )?   (product.prices.sort((a,b)=> a.dateFrom > b.dateFrom? -1:1 ))[0].price : 0) - product?.unitCost).toFixed(2) }}€</td>
            <td class="active-column"><i class="fal" :class="'fa-lightbulb' + (product.active ? '-on':'' )"></i><span class="value">{{ product.active }}</span></td>
            <td class="color-column" :class="{hidden: typeId!=3 && typeId!=5 }" :style="{backgroundColor: hexColor(product.color)}">{{ hexColor(product.color) }}</td>
            <td class="action-column" v-on:click="openProduct(product.id)"><i class="fal fa-pencil"></i></td>
            <td class="action-column"><i class="fal fa-trash-alt" __v-if="categoryId == '' || categoryId == product.categoryId"></i></td>
          </tr>
          </tbody>
          <tfoot></tfoot>
        </table>
      </div>
      <button @click="materials()" class="btn btn-primary col-md-1">
        <i class="fal fa-excel"></i> distinte base
      </button>
    </div>
  </div>
</template>

<script>
import printJS from 'print-js';
import {mapActions, mapState, mapMutations} from "vuex";
import rgbHex from 'rgb-hex';

export default {
  name: "ProductsList",
  data() {
    return {
      typeId: 1,
      categoryId: null,
      showInactive: false,
      oldProducts: [],
      stateClass: 'hidden'
    }
  },

  created() {
/*
    if (localStorage.getItem('products') != null ) {
      //this.oldProducts = JSON.parse(localStorage.getItem('products'));
    }
    this.loadProducts();
*/
  },

  mounted() {
    if (localStorage.getItem('products') != null ) {
      //this.oldProducts = JSON.parse(localStorage.getItem('products'));
    }

    if (localStorage.getItem('products_type_id') != null ) {
      this.typeId = parseInt(JSON.parse(localStorage.getItem('products_type_id')));
    }

    if (localStorage.getItem('products_active') != null ) {
      this.showInactive = JSON.parse(localStorage.getItem('products_active')) == true;
    }
    this.setShowInactive(this.showInactive);
  },

  methods: {
    ...mapActions({
      getProducts: 'product/getProducts',
      getProductById: 'product/getProductById',
      materialsBill: 'product/materialsBill'
    }),
    ...mapMutations('core', ['setCurrentProduct']),

    createNew() {
      this.setCurrentProduct({
        id: null,
        name:'',
        color:'#888',
        productTypeId:3,
        productType:{
          id:3
        },
        languages: [
          {
            id:"1",name:"italian",localeName:"italiano",image:"it.png",
            infos:{name:"",shortName:"",menuName:"",description:"",productId:null,langId:1}
          },
          {
            id:"2",name:"english",localeName:"inglese",image:"en.png",
            infos:{name:"",shortName:"",menuName:"",description:"",productId:null,langId:2}
          }
          ]
      });
    },

    typeChange() {
      localStorage.setItem('products_type_id',this.typeId);
      this.loadProducts();
    },

    categoryChange() {
      localStorage.setItem('category_id',this.categoryId);
      this.loadProducts();
    },

    setShowInactive(show) {
      this.showInactive = show;
      localStorage.setItem('products_active',show);
      this.loadProducts();
    },

    materials() {
      let payload = {
        typeId: this.typeId,
        categoryId: this.categoryId
      };
      this.materialsBill(payload)
        .then(
          (data) => {
            let grid = [];
            data.forEach(el => {
              let unit = (el.unit?.measure??null);
              let row = {
                prodotto: el.name,
                ingredienti: '',
                quantità: el.baseQuantity ? ((unit == 'kg'?'gr':unit) + " " + el.baseQuantity * (unit == 'kg'?1000:1) ) : ''
              }
              grid.push(row);
              if (el.baseQuantity) {
                el.components.forEach(comp => {
                  unit = (comp.unit?.measure??null);
                  row = {
                    prodotto: '',
                    ingredienti: comp.name,
                    quantità: (unit == 'kg'?'gr':unit) + " " + comp.products_components.quantity * (unit == 'kg'?1000:1)
                  }
                  grid.push(row);
                });
              }
            });
            console.log(data);
            printJS({
              printable: grid,
              type: 'json',
              maxWidth: '100%',
              properties: ['prodotto','ingredienti','quantità'],
              header: '<h2 class="custom-h3">Distinte base</h2>',
              style: '.custom-h3 { color: red; }'
            })
            //localStorage.setItem('products',JSON.stringify(this.products));
            //this.oldProducts = this.products;
          }
        )
        .catch(

        );

    },

    loadProducts() {
      let payload = {};

      if (!this.showInactive) {
        payload.active = 1;
      }

      if (this.typeId) {
        payload.typeId = this.typeId;
      }

      if (this.typeId) {
        payload.categoryId = this.categoryId;
      }

      this.getProducts(payload)
        .then(
          () => {
            //console.log(this.products);
            localStorage.setItem('products',JSON.stringify(this.products));
            this.oldProducts = this.products;
          }
        )
        .catch(

        );
    },

    hexColor(color) {
      try {
        return '#' + rgbHex(color);

      }
      catch(e) {
        return 'transparent';
      }
    },

    openProduct(id){
      this.getProductById(id).then(() => {
        this.setCurrentProduct(this.product);
        }
      )
    }

  },


  computed: {
    ...mapState('product', ['products','product']),
    ...mapState('category', ['categories']),
    ...mapState('core', ['currentUser','currentProduct'])
  }

}
</script>

<style scoped>

</style>