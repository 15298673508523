import { createRouter, createWebHistory } from "vue-router";
import Login from "../views/Login.vue";
import Main from "../views/Main.vue";
import Categories from "../views/Categories.vue";
import Products from "../views/Products.vue";
import Locations from "../views/Locations.vue";
import Customers from "../views/Customers.vue";
import Employees from "../views/Employees.vue";
import Suppliers from "../views/Suppliers.vue";
import SupplierOrders from "../views/SupplierOrders.vue";
import SupplierProducts from "../views/SupplierProducts.vue";
import Parameters from "../views/Parameters.vue";
import Printers from "../views/Printers.vue";
import Statistics from "../views/Statistics.vue";
import Warehouse from "../views/Warehouse.vue";
import Workshifts from "../views/Workshifts.vue";

const routes = [
  {
    path: "/",
    name: "Main",
    meta: {
      requiresAuth: true
    },
    component: Main,
  },
  {
    path: "/categories",
    name: "Categories",
    meta: {
      requiresAuth: true
    },
    component: Categories,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/products",
    name: "Products",
    meta: {
      requiresAuth: true
    },
    component: Products,
  },
  {
    path: "/statistics",
    name: "Statistics",
    meta: {
      requiresAuth: true
    },
    component: Statistics,
  },
  {
    path: "/locations",
    name: "Locations",
    meta: {
      requiresAuth: true
    },
    component: Locations,
  },
  {
    path: "/customers",
    name: "Customers",
    meta: {
      requiresAuth: true
    },
    component: Customers,
  },
  {
    path: "/employees",
    name: "Employees",
    meta: {
      requiresAuth: true
    },
    component: Employees,
  },
  {
    path: "/warehouse",
    name: "Warehouse",
    meta: {
      requiresAuth: true
    },
    component: Warehouse,
  },
  {
    path: "/workshifts",
    name: "Workshifts",
    meta: {
      requiresAuth: true
    },
    component: Workshifts,
  },
  {
    path: "/suppliers",
    name: "Suppliers",
    meta: {
      requiresAuth: true
    },
    component: Suppliers,
  },
  {
    path: "/supplier-orders",
    name: "SupplierOrders",
    meta: {
      requiresAuth: true
    },
    component: SupplierOrders,
  },
  {
    path: "/supplier-products/:supplierId",
    name: "Supplier Products",
    meta: {
      requiresAuth: true
    },
    component: SupplierProducts,
  },
  {
    path: "/printers",
    name: "Printers",
    meta: {
      requiresAuth: true
    },
    component: Printers,
  },
  {
    path: "/parameters",
    name: "Parameters",
    meta: {
      requiresAuth: true
    },
    component: Parameters,
  },
  {
    path: "/logout",
    name: "Logout",
    meta: {
      requiresAuth: true
    },
    component: Main,
  }
];


const router = createRouter({
  history: createWebHistory(),
  routes,
  mode: 'history'
});

import modCore from '@/store/modules/core';
console.log(modCore);


router.beforeEach(async(to, from,next) => {

  //console.log(modCore.state.authenticated);
  console.log(to.query);

  if (to.name != 'Login' && to.meta.requiresAuth && !modCore.state.authenticated) {
    console.log('go to login');
    next({name:'Login'});
    //next();
  }
  else next();
});

export default router;
