<template>
  <div id="supplier-order-drafts-form" :class="{ hidden: currentSupplierOrderDraft==null }">
    <div class="content main box">
      <div v-if="currentSupplierOrderDraft">
        <div class="form-floating">
          <input type="datetime-local" id="created_at" :value="currentSupplierOrderDraft.createdAt" name="created_at" class="form-control">
          <label class="form-label" for="created_at">Data creazione</label>
        </div>
        <div class="table-wrapper">
          <table class="items-list table table-hover table-striped table-autofit">
            <thead>
            <tr>
              <th>prodotto</th>
              <th>unità</th>
              <th>quantità</th>
              <th>forn</th>
              <th colspan="2">azioni</th>
            </tr>
            </thead>
            <tbody>
            <template v-for="item in currentSupplierOrderDraft.supplierOrderDraftItems">
            <tr v-if="!item.deleted" :class="{modified: item.modified, new:item.isNew}">
              <td>{{ item.product.name }}</td>
              <td>{{ item.product.unit.measure }}</td>
              <td>{{ item.quantity }}</td>
              <td>{{ item.supplier?.displayName }}</td>
              <td @click="setCurrentSupplier(supplier)"><i class="fal fa-pencil"></i></td>
              <td @click="item.deleted = true"><i class="fal fa-trash-alt"></i></td>
            </tr>
            </template>
            <tr>
              <td style="position:relative;">
                <input type="search" v-model="productSearch" placeholder="cerca prodotto">
                <select @change="setSelectedProduct(selectedProduct)" v-model="selectedProduct" v-if="productSearch != ''" style="position: fixed; background-color: white; border: 1px solid #ddd; overflow: scroll; max-height: 200px">
                  <option :value="product" v-for="product in suppliedProducts.filter(item => item.name.toLowerCase().includes(productSearch.toLowerCase()))">{{ product.name }}</option>
                </select>
                {{ selectedProduct?.name }}
              </td>
              <td>
                {{ selectedProduct?.unit.measure }}
              </td>
              <td>
                <input v-model="quantity" type="number" step="0.001">
              </td>
              <td>
                <select>
                  <option value="" v-for="supplier in selectedProduct?.suppliers"> {{ supplier.displayName }}</option>
                </select>
              </td>
              <td colspan="2">
                <button @click="addProduct()" class="btn btn-primary">aggiungi</button>
              </td>
            </tr>
            </tbody>
            <tfoot></tfoot>
          </table>

        </div>

        <div>
        </div>
        <div class="container">
          <div class="row">
            <div class="col-md-6">
            </div>
            <div class="col-md-3">
              <button class="btn btn-secondary" type="button" @click="close()"><i class="fal fa-times"></i> cancel</button>
            </div>
            <div class="col-md-3">
              <button class="btn btn-primary" type="button" @click="saveDraft()"><i class="fal fa-save"></i> salva</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapState, mapMutations} from "vuex";

export default {
  name: "SupplierOrderDraftsForm",
  data() {
    return {
      productSearch: '',
      selectedProduct: null,
      quantity: 0,
      oldSupplierProducts: [],
      suppliedProducts: [],
      stateClass: 'hidden'
    }
  },

  created() {
    if (localStorage.getItem('suppliers') != null ) {
      this.oldSuppliers = JSON.parse(localStorage.getItem('suppliers'));
    }
    this.getSuppliers({active:1})
        .then(
            () => {
              //console.log(this.suppliers);
              localStorage.setItem('suppliers',JSON.stringify(this.suppliers));
              this.oldSuppliers = this.suppliers;
            }
        )
        .catch(

        );
  },

  mounted() {
    this.getLists()
  },

  methods: {
    ...mapActions({
      updateSupplierOrderDraft: 'supplierOrder/updateSupplierOrderDraftById',
      createSupplierOrderDraft: 'supplierOrder/createSupplierOrderDraft',
      getSupplierOrderDrafts: 'supplierOrder/getSupplierOrderDrafts',
      getSuppliers: 'supplier/getSuppliers',
      getProductsWithSuppliers: 'product/getProductsWithSuppliers',
      getSupplierProducts: 'supplierProduct/getSupplierProducts'
    }),
    ...mapMutations('core', ['setCurrentSupplierOrderDraft']),

    close() {
      this.setCurrentSupplierOrderDraft(null);
    },

    keyPressed(event) {
      switch(event.code) {
        case 'Escape':
          this.close();
          break;
      }
      event.stopImmediatePropagation();
    },

    getLists() {
      if (localStorage.getItem('suppliedProducts') != null ) {
        this.oldSupplierProducts = JSON.parse(localStorage.getItem('supplierProducts'));
        this.updateSuppliedProducts();
      }
      this.getProductsWithSuppliers({}).then(
        (data) => {
          //console.log(this.suppliers);
          localStorage.setItem('suppliedProducts',JSON.stringify(data));
          this.suppliedProducts = data;
          this.updateSuppliedProducts();
        }
      ).catch();
      /*
      this.getSupplierProducts({})
        .then(
          (data) => {
            //console.log(this.suppliers);
            localStorage.setItem('suppliers',JSON.stringify(this.supplierProducts));
            this.oldSupplierProducts = this.supplierProducts;
            this.updateSuppliedProducts();
          }
        )
        .catch(

        );
      */
    },

    updateSuppliedProducts() {
      //this.suppliedProducts = [];
      /*
      this.oldSupplierProducts.forEach((supplierProduct) => {
        if (!this.suppliedProducts.find((item) => item.id == supplierProduct.product.id)) {
          this.suppliedProducts.push(supplierProduct.product);
        }
      });
       */
      this.suppliedProducts.sort((a,b)=> a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1 );

    },

    setSelectedProduct(product) {
      console.log(product);
      this.productSearch = '';
    },

    addProduct() {
      this.currentSupplierOrderDraft.supplierOrderDraftItems.push({supplierOrderDraftId: this.currentSupplierOrderDraft.id, productId:this.selectedProduct.id, product: this.selectedProduct, deleted: false, modified:false, isNew: true, quantity: this.quantity});
      this.quantity = 0;
      this.productSearch = '';
      this.selectedProduct = null;
    },

    saveDraft() {
      if (this.currentSupplierOrderDraft.id) {
        this.updateSupplierOrderDraft(this.currentSupplierOrderDraft).then(()=>{
          this.getSupplierOrderDrafts().then(() => {this.close();});
        }).catch((err) => {
          this.productSearch = 'ERRORE!';
        });
      }
      else {
        this.productSearch = 'Nuova bozza';
      }
    }


  },

  computed: {
    ...mapState('supplier', ['suppliers']),
    ...mapState('supplierProduct', ['supplierProducts']),
    ...mapState('core', ['currentUser','currentSupplierOrderDraft'])
  }

}
</script>

<style scoped>

</style>