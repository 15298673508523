import axiosRequest from '@/misc/apiService'

const modWorkshift = {
  namespaced: true,

  state: {
    workshifts: [],
    workshift: null
  },

  getters: {},

  mutations: {
    setWorkshifts (state, payload) {
      state.workshifts = payload
    },
    setWorkshift (state, payload) {
      state.workshift = payload
    }

  },

  actions: {
    getWorkshifts ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const params = payload
        let url = '/workshifts'
        axiosRequest({
          method: 'get',
          url,
          params
        })
          .then((data) => {
            commit('setWorkshifts', data.length ? Object.values(data) : [])
            resolve(data)
          })
          .catch((err) => reject(err))
      })
    },

    createWorkshift ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const params = payload
        axiosRequest({
          method: 'post',
          url: 'workshifts',
          params: params
        })
          .then((data) => {
            resolve(data)
          })
          .catch((err) => reject(err))
      })
    },

    sendAll ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axiosRequest({
          method: 'post',
          url: `workshifts/send`
        })
          .then((data) => {
            resolve(data)
          })
          .catch((err) => reject(err))
      })
    },

    deleteWorkshiftById ({ commit }, workshiftId) {
      return new Promise((resolve, reject) => {
        axiosRequest({
          method: 'delete',
          url: `workshifts/${workshiftId}`
        })
          .then((data) => {
            resolve(data)
          })
          .catch((err) => reject(err))
      })
    },

    getWorkshiftById ({ commit }, workshiftId) {
      return new Promise((resolve, reject) => {
        axiosRequest({
          method: 'get',
          url: `workshifts/${workshiftId}`
        })
          .then((data) => {
            commit('setWorkshift', data)
            resolve(data.response)
          })
          .catch((err) => reject(err))
      })
    },

    updateWorkshiftById ({ dispatch }, payload) {
      return new Promise((resolve, reject) => {
        const params = payload
        axiosRequest({
          method: 'put',
          url: `/workshifts/${payload.id}`,
          params: params
        })
          .then((data) => {
            resolve(data)
          })
          .catch((err) => reject(err))
      })
    }
  }
}

export default modWorkshift
