<template>
  <div>
    <Navigation></Navigation>
    <ParametersList></ParametersList>
    <ParametersForm></ParametersForm>
  </div>
</template>

<script>
import ParametersList from "../components/ParametersList";
import ParametersForm from "../components/ParametersForm";
import Navigation from "../components/Navigation";

export default {
  name: "Parameters",
  components: {
    ParametersList,
    ParametersForm,
    Navigation
  }
}
</script>

<style scoped>

</style>