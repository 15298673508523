<template>
  <div id="warehouse-check-form">
    <div class="content main box">
      <div>
        <div class="page-tools container-fluid">
          <div class="row">
            <div class="col-1 form-check form-switch">
              <input class="form-check-input" id="show_inactive" type="checkbox" v-model="showInactive"> <label for="show_inactive"><i class="fal fa-lightbulb"></i></label>
            </div>
            <div class="col-1">
              <label for="tag">tipologia</label>
            </div>
            <div class="col-4">
              <select @change="saveFilters" class="form-select" v-model="typeFilter">
                <option value="">-- tipo --</option>
                <option :class="type.replaceAll(' ','-')" :value="type" v-for="type in types">{{ type }}</option>
              </select>
            </div>
            <div class="col-2">
              <label for="tag">filtra</label>
            </div>
            <div class="col-4">
              <select @change="saveFilters" class="form-select" v-model="tagFilter">
                <option value="">-- tutti i tag --</option>
                <option :value="tag" v-for="tag in tags">{{ tag }}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="table-wrapper">
          <table class="items-list table table-hover table-striped table-autofit">
            <thead>
            <tr>
              <th>prodotto</th>
              <th>tipo</th>
              <th>unità</th>
              <th>q.tà</th>
              <th>€</th>
              <th><i class="fa fa-tools"></i></th>
            </tr>
            </thead>
            <tbody>
            <template v-for="(item, index) in suppliedProducts.filter(el => (showInactive? true:el.active ) ).filter(el =>  ( tagFilter == '' ||  ((el.tags.trim().replaceAll(', ',',').split(',')).findIndex(val => val == tagFilter)>=0)  ) /** / && /**/ /* */).filter( el => /**/ typeFilter == '' || (el.productType.name == typeFilter))">
            <tr :class="{inactive: !item.active, modified: item.quantity != (item.newQuantity??item.quantity)}">
              <td>{{ item.name }}</td>
              <td><i class="fal" :class="'fa-' + typeIcons[item.productTypeId]"></i></td>
              <td>{{ item.unit.measure }}</td>
              <td @click="showInput(item)">{{ item.newQuantity??item.quantity }}</td>
              <td>€ {{ (item.unitCost * (item.newQuantity??item.quantity??0.0)).toFixed(2)  }}</td>
              <td><span @click="resetItem(item)" v-if="item.quantity != (item.newQuantity??item.quantity)"><i class="fal fa-undo"></i></span><span @click="showInput(item,true)" v-if="item.quantity == (item.newQuantity??item.quantity)"><i class="fal fa-boxes-packing"></i></span></td>
            </tr>
            </template>
            </tbody>
            <tfoot>
              <tr v-if="suppliedProducts.length > 0"><th colspan="4">Valore totale merce</th><td>{{ (totalValue??0).toFixed(2) }}</td><td></td></tr>
            </tfoot>
          </table>
        </div>

        <div v-if="selectedProduct" class="warehouse_quantity">
          <div>
            <h3>{{ increaseMode ? 'Aggiungi': 'modifica'}} quantità per "{{ selectedProduct.name }}"</h3>
            <div class="content">
              <label>{{ selectedProduct.unit.measure }}</label>
              <input style="width: 5em;" :type="increaseMode?'hidden':'number'" size="10" :step="selectedProduct.unit.measure == 'nr'?'1':'0.001'" v-model="quantity">
              <input style="width: 5em;" v-if="increaseMode" type="number" size="10" :step="selectedProduct.unit.measure == 'nr'?'1':'0.001'" v-model="increase">
              <div v-if="increaseMode" style="width: 5em;">{{ quantity + increase }}</div>
              <button type="button" @click="setQuantity" class="btn btn-primary"><i class="fal" :class="increaseMode?'fa-plus':'fa-arrow-down-to-line'"></i> {{ increaseMode ? 'Aggiungi': 'Aggiorna'}}</button>
              <button type="button" @click="selectedProduct = null" class="btn btn-secondary"><i class="fal fa-times"></i> annulla</button>
            </div>
          </div>
        </div>

        <div>
        </div>
        <div class="container">
          <div class="row">
            <div class="col-sm-6 col-md-9">
            </div>
            <div class="col-sm-6 col-md-3">
              <button class="btn btn-primary" type="button" @click="update({products: suppliedProducts.filter(item => item.quantity != (item.newQuantity?? item.quantity) )})"><i class="fal fa-save"></i> salva</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapState, mapMutations} from "vuex";

export default {
  name: "WarehouseCheck",
  data() {
    return {
      increaseMode: false,
      showInactive: false,
      typeIcons: ['','flask','pie','burger-soda','','','salt-shaker','','toilet-paper'],
      tagFilter: '',
      typeFilter: 'materie prime',
      tags: [],
      types: [],
      quantity: 0,
      increase: 0,
      productSearch: '',
      selectedProduct: null,
      oldSupplierProducts: [],
      suppliedProducts: [],
      stateClass: 'hidden'
    }
  },

  created() {
  },

  mounted() {
    this.getLists()
  },

  methods: {
    ...mapActions({
      inventoryUpdate: 'product/inventoryUpdate',
      getInventoryProducts: 'product/getInventoryList',
      getSupplierProducts: 'supplierProduct/getSupplierProducts'
    }),
    ...mapMutations('core', ['setCurrentSupplierOrderDraft']),

    close() {
      this.setCurrentSupplierOrderDraft(null);
    },

    keyPressed(event) {
      switch(event.code) {
        case 'Escape':
          this.close();
          break;
      }
      event.stopImmediatePropagation();
    },

    saveFilters() {
      localStorage.setItem('warehouseCheckTypeFilter',this.typeFilter);
      localStorage.setItem('warehouseCheckTagFilter',this.tagFilter);
    },

    getLists() {
      if (localStorage.getItem('supplierProducts') != null ) {
        this.oldSupplierProducts = JSON.parse(localStorage.getItem('supplierProducts'));
        this.updateSuppliedProducts();
      }
      if (localStorage.getItem('warehouseCheckTypeFilter') != null ) {
        this.typeFilter = localStorage.getItem('warehouseCheckTypeFilter');
      }
      if (localStorage.getItem('warehouseCheckTagFilter') != null ) {
        this.tagFilter = localStorage.getItem('warehouseCheckTagFilter');
      }
      this.getInventoryProducts({}).then((data)=> {
        console.log(data[0]);
        console.log(data[4]);
        this.suppliedProducts = data.filter(el => el.productTypeId != 3 || (el.stockManaged && el.active));
        this.suppliedProducts.forEach(product => {
          product.tags.split(',').forEach(tag => {
            let tagOk = tag.trim();
            if (!this.tags.find(el => el == tagOk) && tagOk!='')
              this.tags.push(tagOk);
          });

          if (!this.types.find(el => el == product.productType.name))
            this.types.push(product.productType.name);
        })
        this.sortList();
        this.tags.sort();
        this.types.sort();
        this.saveFilters();
      });
      /*
      this.getSupplierProducts({})
        .then(
          () => {
            //console.log(this.suppliers);
            localStorage.setItem('supplierProducts',JSON.stringify(this.supplierProducts));
            this.oldSupplierProducts = this.supplierProducts;
            this.updateSuppliedProducts();
          }
        )
        .catch(

        );
      */
    },

    update(params) {
      this.inventoryUpdate(params).then(() => {
        this.getLists();
        }
      );
    },

    updateSuppliedProducts() {
      this.suppliedProducts = [];
      this.oldSupplierProducts.forEach((supplierProduct) => {
        if (!this.suppliedProducts.find((item) => item.id == supplierProduct.product.id)) {
          if (!this.tags.find(el => el == supplierProduct.product.tags) && supplierProduct.product.tags!='' )
            this.tags.push(supplierProduct.product.tags);
          //if (Math.random() > 0.5) supplierProduct.product.newQuantity = supplierProduct.product.quantity + 1;
          this.suppliedProducts.push(supplierProduct.product);
        }
      });
      this.tags.sort();
      this.sortList();
    },

    sortList() {
      this.suppliedProducts.sort((a,b)=> {
        return (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1);
        /* if (!this.groupByTags)
          return (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1);
        else
          if (a.tags == b.tags)
            return (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1);
          else
            return (a.tags > b.tags? 1:-1);

         */
      });
    },

    showInput(product, increase = false) {
      this.increaseMode = increase;
      this.increase = 0;
      this.selectedProduct = product;
      this.quantity = product.newQuantity??product.quantity;
    },

    resetItem(product) {
      product.newQuantity = product.quantity;
    },

    setQuantity() {
      this.quantity += this.increase;
      this.selectedProduct.newQuantity = this.quantity;
      this.selectedProduct = null;
    }

  },

  computed: {
    ...mapState('supplier', ['suppliers']),
    ...mapState('supplierProduct', ['supplierProducts']),
    ...mapState('core', ['currentUser','currentSupplierOrderDraft']),
    totalValue() {
      let total = 0;

      if (this.suppliedProducts)
        this.suppliedProducts.filter(el => (this.showInactive? true:el.active ) ).filter(el =>  ( this.tagFilter == '' ||  ((el.tags.trim().replaceAll(', ',',').split(',')).findIndex(val => val == this.tagFilter)>=0)  ) ).filter( el =>  this.typeFilter == '' || (el.productType.name == this.typeFilter)).forEach(item => {
          total += (item.unitCost * (item.quantity??0.0));
        });
      return total;
    }
  }

}
</script>

<style scoped>

</style>