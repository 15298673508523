<template>
  <div id="categories" :class="{ hidden:currentCategory!=null }">
    <div class="content main box">

      <div class="page-tools container">
        <div class="row">
          <button @click="createNew()" class="btn btn-primary col-md-1">
            <i class="fal fa-plus"></i> aggiungi
          </button>
          <div class="col-md-1"></div>
          <button @click="setShowInactive(!showInactive)" class="btn btn-secondary col-md-1">
            <i class="fal" :class="showInactive?'fa-eye':'fa-eye-slash'"></i> inattivi
          </button>
        </div>
      </div>
      <div class="table-wrapper">
        <table class="items-list table table-hover table-striped table-autofit">
          <thead>
            <tr>
              <th>nome</th>
              <th>attivo</th>
              <th>colore</th>
              <th colspan="2">azioni</th>
            </tr>
          </thead>
          <tbody>
            <tr :class="category.active?'':'inactive'" :key="category.id" v-for="category in oldCategories">
              <td>{{ category.name }}</td>
              <td class="active-column"><i class="fal" :class="'fa-lightbulb' + (category.active ? '-on':'' )"></i><span class="value">{{ category.active }}</span></td>
              <td class="color-column" :style="{backgroundColor: category.color}">{{ category.color }}</td>
              <td class="action-column" @click="setCurrentCategory(category)"><i class="fal fa-pencil"></i></td>
              <td class="action-column"><i class="fal fa-trash-alt"></i></td>
            </tr>
          </tbody>
          <tfoot></tfoot>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapState, mapMutations} from "vuex";

export default {
  name: "CategoriesList",
  data() {
    return {
      oldCategories: [],
      showInactive: false,
      stateClass: 'hidden'
    }
  },

  created() {
/*
    if (localStorage.getItem('categories') != null ) {
      this.oldCategories = JSON.parse(localStorage.getItem('categories'));
    }
    this.getCategories({active:1})
        .then(
            () => {
              //console.log(this.categories);
              localStorage.setItem('categories',JSON.stringify(this.categories));
              this.oldCategories = this.categories;
            }
        )
        .catch(

        );
*/
  },

  mounted() {
    if (localStorage.getItem('categories') != null ) {
      this.oldCategories = JSON.parse(localStorage.getItem('categories'));
    }
    if (localStorage.getItem('categories_active') != null ) {
      this.showInactive = JSON.parse(localStorage.getItem('categories_active')) == true;
    }
    this.setShowInactive(this.showInactive);


  },

  methods: {
    ...mapActions({
      getCategories: 'category/getCategories'
    }),
    ...mapMutations('core', ['setCurrentCategory','setCurrentLocation']),

    createNew() {
      this.setCurrentCategory({name:'', color:''});
    },

    loadCategories() {
      let payload = {};

      if (!this.showInactive) {
        payload = {active:1};
      }

      this.getCategories(payload)
        .then(
          () => {
            //console.log(this.categories);
            localStorage.setItem('categories',JSON.stringify(this.categories));
            this.oldCategories = this.categories;
          }
        )
        .catch(

        );
    },

    setShowInactive(show) {
      this.showInactive = show;
      localStorage.setItem('categories_active',show);
      this.loadCategories();
    }

  },

  watch: {
    currentLocation: function(val) {
      this.stateClass = val == null? 'hidden':''
    }
  },

  computed: {
    ...mapState('category', ['categories']),
    ...mapState('core', ['currentUser','currentCategory','currentLocation'])
  }

}
</script>

<style scoped>

</style>