<template>
  <div id="locations-map" :class="{ hidden: ( !currentLocation || !showMapOrList ) }">
    <div class="content main box">
      <div class="page-tools">
        <button @click="close()" class="btn btn-secondary">
          <i class="fal fa-times"></i> chiudi
        </button>
      </div>
      <h2 v-if="currentLocation">{{ currentLocation.name}}</h2>
      <div class="desks" :class="{ map: currentLocation?.isTakeaway == 0 }">
        <div class="desk" @click="openForm(location)" :class="{ movable: currentLocation?.isTakeaway == 0 }" :key="location.id" v-for="location in currentLocation?.children" :style="{
          left: location.mapInfo?.split('|')[0] + 'vw',
          top: location.mapInfo?.split('|')[1] + 'vw',
          width: location.mapInfo?.split('|')[2] + 'vw',
          height: location.mapInfo?.split('|')[3] + 'vw'
        }">
          {{ location.name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapState, mapMutations} from "vuex";

export default {
  name: "LocationsMap",
  data() {
    return {
      room: null,
      oldLocations: [],
      stateClass: 'hidden'
    }
  },

  created() {

  },

  mounted() {

  },

  methods: {
    ...mapActions({
      getLocations: 'location/getLocations'
    }),
    ...mapMutations('core', ['setCurrentLocation','setShowMapOrList']),

    createNew() {
      this.setCurrentLocation({name:'', color:''});
    },

    openForm(location) {
      this.setShowMapOrList(false);
      this.setCurrentLocation(location);
    },

    close() {
      this.setCurrentLocation(null);
      this.setShowMapOrList(true);
    }

  },


  computed: {
    ...mapState('location', ['locations']),
    ...mapState('core', ['currentUser','currentLocation','showMapOrList'])
  }

}
</script>

<style scoped>

</style>