<template>
  <div id="supplier-orders" :class="{ hidden: currentSupplierOrder!=null || currentSupplierOrderDraft!=null }">
    <div class="content main box">

      <div class="page-tools container">
        <div class="row">
          <button @click="createNew()" class="btn btn-primary">
            <i class="fal fa-plus"></i> aggiungi
          </button>
        </div>
      </div>
      <div class="table-wrapper">
        <table class="items-list table table-hover table-striped table-autofit">
          <thead>
          <tr>
            <th>data creazione</th>
            <th>creato da</th>
            <th>stato</th>
            <th colspan="3">azioni</th>
          </tr>
          </thead>
          <tbody>
          <tr :key="supplierOrderDraft.id" v-for="supplierOrderDraft in oldSupplierOrderDrafts">
            <td>{{ supplierOrderDraft.createdAt ? (new Date(Date.parse(supplierOrderDraft.createdAt))).toLocaleString('it-IT') : ''}}</td>
            <td>{{ supplierOrderDraft.user.name }}</td>
            <td>{{ supplierOrderDraft.status }}</td>
            <td @click="openSupplierOrderDraft(JSON.parse(JSON.stringify(supplierOrderDraft)))"><i class="fal fa-pencil"></i></td>
            <td @click="router().push('/supplier-products/'+supplierOrder.id)"><i class="fal fa-list"></i></td>
            <td><i class="fal fa-trash-alt"></i></td>
          </tr>
          </tbody>
          <tfoot></tfoot>
        </table>

      </div>
      <div class="table-wrapper">
        <table class="items-list table table-hover table-striped table-autofit">
          <thead>
          <tr>
            <th>fornitore</th>
            <th>data creazione</th>
            <th>stato</th>
            <th colspan="3">azioni</th>
          </tr>
          </thead>
          <tbody>
          <tr :key="supplierOrder.id" v-for="supplierOrder in oldSupplierOrders">
            <td>{{ supplierOrder.supplier.displayName }}</td>
            <td>{{ supplierOrder.createdAt ? (new Date(Date.parse(supplierOrder.createdAt))).toLocaleString('it-IT') : '' }}</td>
            <td>{{ supplierOrder.status }}</td>
            <td @click="setCurrentSupplier({...supplier})"><i class="fal fa-pencil"></i></td>
            <td @click="router().push('/supplier-products/'+supplierOrder.id)"><i class="fal fa-list"></i></td>
            <td><i class="fal fa-trash-alt"></i></td>
          </tr>
          </tbody>
          <tfoot></tfoot>
        </table>

      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapState, mapMutations} from "vuex";
import router from "@/router";

export default {
  name: "SupplierOrdersList",
  props: {
    supplierId: String,
  },
  data() {
    return {
      showInactive: true,
      oldSupplierOrders: [],
      oldSupplierOrderDrafts: [],
      stateClass: 'hidden'
    }
  },

  created() {
    this.loadSupplierOrders()
  },

  mounted() {
    this.loadSupplierOrders()
  },

  methods: {
    router() {
      return router
    },
    ...mapActions({
      getSupplierOrders: 'supplierOrder/getSupplierOrders',
      getSupplierOrderDrafts: 'supplierOrder/getSupplierOrderDrafts'
    }),
    ...mapMutations('core', ['setCurrentSupplierOrder','setCurrentSupplierOrderDraft','setCurrentLocation']),

    createNew() {
      this.setCurrentSupplierOrderDraft({createdAt: new Date(), supplierOrderDraftItems:[]});
    },

    openSupplierOrderDraft(draft) {
      draft.supplierOrderDraftItems.forEach(el => {
        el.modified = false;
        el.deleted = false;
        el.isNew = false;
      });
      this.setCurrentSupplierOrderDraft(draft);
    },

    loadSupplierOrders() {
      if (localStorage.getItem('supplierOrders') != null ) {
        this.oldSupplierOrders = JSON.parse(localStorage.getItem('supplierOrders'));
      }
      this.getSupplierOrders({})
        .then(
          () => {
            //console.log(this.suppliers);
            localStorage.setItem('supplierOrders',JSON.stringify(this.supplierOrders));
            this.oldSupplierOrders = this.supplierOrders;
          }
        )
        .catch(

        );
      this.getSupplierOrderDrafts({})
        .then(
          () => {
            //console.log(this.suppliers);
            localStorage.setItem('supplierOrderDrafts',JSON.stringify(this.supplierOrderDrafts));
            this.oldSupplierOrderDrafts = this.supplierOrderDrafts;
          }
        )
        .catch(

        );

    },



  },

  computed: {
    ...mapState('supplierOrder', ['supplierOrders','supplierOrderDrafts']),
    ...mapState('core', ['currentUser','currentSupplierOrder','currentSupplierOrderDraft','currentLocation'])
  }

}
</script>

<style scoped>

</style>