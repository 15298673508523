<template>
  <div id="authentication" :class="stateClass">
    <h2 class="logo"><span class="brand-color-1">Risto</span><span class="brand-color-2">Matico</span></h2>
    <div class="content">
        <label for="login" class="">Username</label>
        <div class="field">
          <input type="text" id="login" class="" autocomplete="login" v-model="login">

        </div>
        <label for="password" class="">Password</label>
        <div class="field">
          <input type="password" id="password" autocomplete="password" class="" v-model="password">
        </div>
        <button type="submit" v-on:click="signInUser">Accedi <i class="fal fa-sign-in"></i></button>
        <div v-if="showSnackbar" class="result">
          {{ error }}
        </div>
      </div>
  </div>
</template>

<script>

import {mapActions, mapState} from 'vuex'

export default {
  name: "Authentication",


  created () {
    this.getParameters()
      .then(() => {
        for (let p in this.parameters) {
          this.config[this.parameters[p].name] = this.parameters[p].value;
          if (this.parameters[p].name === 'company') {
            this.company = this.parameters[p].value
          }
          if (this.parameters[p].name === 'logo') {
            this.logo = this.parameters[p].value
          }
        }
        // this.initForm()
      })
      .catch( err => {

      })

  },

  data () {
    return {
      stateClass: '',
      company: 'aaa',
      logo: require('@/assets/img/fab_totum.svg'),
      title: '',
      login: '',
      password: '',
      error: 'Inserire nome utente e password',
      showSnackbar: false,
      config: {
        sidebarBackgroundColor: '#888',
        appBackgroundColor: '#ddd',
        accentBackgroundColor: '#6ab'
      }

    }
  },

  computed: {

    ...mapState('parameter', ['parameters']),
    ...mapState('product', ['products']),
    ...mapState('core', ['preorder','currentUser','currentCategory','currentLocation']),
    cssProps () {
      return {
        '--sidebar-bg-color': this.config.sidebarBackgroundColor,
        '--app-bg-color': this.config.appBackgroundColor,
        '--accent-bg-color': this.config.accentBackgroundColor
      }
    }
  },

  methods: {
    ...mapActions({
      authCheck: 'core/authCheck',
      getParameters: 'parameter/getParameters',
      sendWhatsapp: 'whatsapp/send'
    }),

    signInUser () {
      var doc = window.document;
      var docEl = doc.documentElement;

      var requestFullScreen = docEl.requestFullscreen || docEl.mozRequestFullScreen || docEl.webkitRequestFullScreen || docEl.msRequestFullscreen;
      var cancelFullScreen = doc.exitFullscreen || doc.mozCancelFullScreen || doc.webkitExitFullscreen || doc.msExitFullscreen;

      if(!doc.fullscreenElement && !doc.mozFullScreenElement && !doc.webkitFullscreenElement && !doc.msFullscreenElement) {
        //requestFullScreen.call(docEl);
        //isFullScreen = true;

      }


      if (this.login != '' /*&& this.password != ''*/) {
        this.showSnackbar = false;
        this.authCheck({ login: this.login, password: this.password })
          .then((data) => {

            //this.sendWhatsapp({message:`Utente ${this.currentUser.name} ha effettuato il login`});
            this.$router.push(data.level > 1 ? '/workshifts':'/');
            console.log('done');
          })
          .catch((err) => {
            console.log(err);
            this.error = 'Credenziali errate!';
            this.showSnackbar = true
          })
      }
      else {

      }
    },

    getValidationClass (fieldName) {
      const field = this.$v[fieldName]

      if (field) {
        return {
          'md-invalid': field.$invalid && field.$dirty
        }
      }
    }
  },

  watch: {
    currentUser: function(val) {
      if (val)
        this.stateClass = 'hidden';
      else
        this.stateClass = '';
    }

  }

};
</script>

<style scoped>

</style>