<template>
  <div id="parameters-form" class="dialog" :class="{ hidden:currentParameter==null }">
    <div class="content box">
      <form v-if="currentParameter">
        <div class="form-check form-switch">
          <input type="checkbox" id="active" :value="currentParameter.active" name="active" class="form-check-input">
          <label class="form-check-label" for="active">active</label>
        </div>
        <div class="form-floating">
          <input type="text" id="name" :value="currentParameter.name" name="name" class="form-control">
          <label class="form-label" for="name">name</label>
        </div>
        <div class="form-floating">
          <input type="color" id="color" :value="currentParameter.color" name="color" class="form-control">
          <label class="form-label" for="name">color</label>
        </div>
        <div class="form-floating">
          <input type="text" id="name" :value="currentParameter.name" name="name" class="form-control">
          <label class="form-label" for="name">name</label>
        </div>
        <div class="form-floating">
          <input type="text" id="name" :value="currentParameter.name" name="name" class="form-control">
          <label class="form-label" for="name">name</label>
        </div>
        <div class="container">
          <div class="row">
            <div class="col-md-6">
            </div>
            <div class="col-md-3">
              <button class="btn btn-secondary" type="button" @click="close()"><i class="fal fa-times"></i> cancel</button>
            </div>
            <div class="col-md-3">
              <button class="btn btn-primary"><i class="fal fa-save"></i> salva</button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import {mapActions, mapState, mapMutations} from "vuex";

export default {
  name: "ParametersForm",
  data() {
    return {
      currentParameter: null,
      oldParameters: [],
      stateClass: 'hidden'
    }
  },

  created() {
    if (localStorage.getItem('parameters') != null ) {
      this.oldParameters = JSON.parse(localStorage.getItem('parameters'));
    }
    this.getParameters({active:1})
        .then(
            () => {
              //console.log(this.parameters);
              localStorage.setItem('parameters',JSON.stringify(this.parameters));
              this.oldParameters = this.parameters;
            }
        )
        .catch(

        );
  },

  mounted() {
    if (localStorage.getItem('parameters') != null ) {
      this.oldParameters = JSON.parse(localStorage.getItem('parameters'));
    }
    this.getParameters({})
        .then(
            () => {
              //console.log(this.parameters);
              localStorage.setItem('parameters',JSON.stringify(this.parameters));
              this.oldParameters = this.parameters;
            }
        )
        .catch(

        );
  },

  methods: {
    ...mapActions({
      getParameters: 'parameter/getParameters'
    }),
    ...mapMutations('core', ['setCurrentParameter','setCurrentLocation'])
  },

  computed: {
    ...mapState('parameter', ['parameters']),
    ...mapState('core', ['currentUser','currentParameter','currentLocation'])
  }

}
</script>

<style scoped>

</style>