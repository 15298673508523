<template>
  <div id="dashboard">
    <div class="content main box">

      <div class="page-tools container">
        <h1>Dashboard</h1>
      </div>

      <EmployeesCost v-if="currentUser?.level < 2"></EmployeesCost>

    </div>
  </div>
</template>

<script>
import EmployeesCost from "../components/EmployeesCost";
import {mapActions, mapState, mapMutations} from "vuex";
import rgbHex from 'rgb-hex';

export default {
  name: "Dashboard",
  components: {
    EmployeesCost
  },
  data() {
    return {
    }
  },

  created() {
  },

  mounted() {
  },

  methods: {
    ...mapActions({
    }),
    ...mapMutations('core', ['setCurrentProduct']),

    createNew() {
      this.setCurrentProduct({
        id: null,
        name:'',
        color:'#888',
        productTypeId:3,
        productType:{
          id:3
        },
        languages: [
          {
            id:"1",name:"italian",localeName:"italiano",image:"it.png",
            infos:{name:"",shortName:"",menuName:"",description:"",productId:null,langId:1}
          },
          {
            id:"2",name:"english",localeName:"inglese",image:"en.png",
            infos:{name:"",shortName:"",menuName:"",description:"",productId:null,langId:2}
          }
          ]
      });
    },

    typeChange() {
      localStorage.setItem('products_type_id',this.typeId);
      this.loadProducts();
    },

    categoryChange() {
      localStorage.setItem('category_id',this.categoryId);
      this.loadProducts();
    },

    setShowInactive(show) {
      this.showInactive = show;
      localStorage.setItem('products_active',show);
      this.loadProducts();
    },

    materials() {
      let payload = {
        typeId: this.typeId,
        categoryId: this.categoryId
      };
      this.materialsBill(payload)
        .then(
          (data) => {
            let grid = [];
            data.forEach(el => {
              let unit = (el.unit?.measure??null);
              let row = {
                prodotto: el.name,
                ingredienti: '',
                quantità: el.baseQuantity ? ((unit == 'kg'?'gr':unit) + " " + el.baseQuantity * (unit == 'kg'?1000:1) ) : ''
              }
              grid.push(row);
              if (el.baseQuantity) {
                el.components.forEach(comp => {
                  unit = (comp.unit?.measure??null);
                  row = {
                    prodotto: '',
                    ingredienti: comp.name,
                    quantità: (unit == 'kg'?'gr':unit) + " " + comp.products_components.quantity * (unit == 'kg'?1000:1)
                  }
                  grid.push(row);
                });
              }
            });
            console.log(data);
            printJS({
              printable: grid,
              type: 'json',
              maxWidth: '100%',
              properties: ['prodotto','ingredienti','quantità'],
              header: '<h2 class="custom-h3">Distinte base</h2>',
              style: '.custom-h3 { color: red; }'
            })
            //localStorage.setItem('products',JSON.stringify(this.products));
            //this.oldProducts = this.products;
          }
        )
        .catch(

        );

    },

    loadProducts() {
      let payload = {};

      if (!this.showInactive) {
        payload.active = 1;
      }

      if (this.typeId) {
        payload.typeId = this.typeId;
      }

      if (this.typeId) {
        payload.categoryId = this.categoryId;
      }

      this.getProducts(payload)
        .then(
          () => {
            //console.log(this.products);
            localStorage.setItem('products',JSON.stringify(this.products));
            this.oldProducts = this.products;
          }
        )
        .catch(

        );
    },

    hexColor(color) {
      try {
        return '#' + rgbHex(color);

      }
      catch(e) {
        return 'transparent';
      }
    },

    openProduct(id){
      this.getProductById(id).then(() => {
        this.setCurrentProduct(this.product);
        }
      )
    }

  },


  computed: {
    ...mapState('product', ['products','product']),
    ...mapState('category', ['categories']),
    ...mapState('core', ['currentUser','currentProduct'])
  }

}
</script>

<style scoped>

</style>