<template>
  <div id="locations-form" class="dialog" :class="{ hidden: !currentLocation || showMapOrList }">
    <div class="content box">
      <form v-if="currentLocation">
        <div class="form-check form-switch">
          <input type="checkbox" id="active" :checked="currentLocation?.active" :value="true" name="active" class="form-control form-check-input">
          <label class="form-check-label" for="active">active</label>
        </div>
        <div class="form-floating">
          <input type="text" id="name" :value="currentLocation.name" name="name" class="form-control">
          <label class="form-label" for="name">name</label>
        </div>
        <div class="form-floating">
          <input type="color" id="color" :value="currentLocation.color" name="color" class="form-control">
          <label class="form-label" for="name">color</label>
        </div>
        <div class="container">
          <div class="row">
            <div class="col-md-6">
            </div>
            <div class="col-md-3">
              <button class="btn btn-secondary" type="button" @click="close()"><i class="fal fa-times"></i> cancel</button>
            </div>
            <div class="col-md-3">
              <button class="btn btn-primary"><i class="fal fa-save"></i> salva</button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import {mapActions, mapState, mapMutations} from "vuex";

export default {
  name: "LocationsForm",
  data() {
    return {
      oldLocations: [],
      stateClass: 'hidden'
    }
  },

  created() {

  },

  mounted() {

  },

  methods: {
    ...mapActions({
      getLocations: 'location/getLocations'
    }),
    ...mapMutations('core', ['setCurrentLocation']),

    close() {
      this.setCurrentLocation(null);
    }

  },

  computed: {
    ...mapState('location', ['locations']),
    ...mapState('core', ['currentUser','currentLocation','showMapOrList'])
  }

}
</script>

<style scoped>

</style>