<template>
  <div id="printers-form" class="dialog" :class="{ hidden:currentPrinter==null }">
    <div class="content box container" v-if="currentPrinter!=null" >
      <h2 class="title"> {{ currentPrinter?.id ? 'Modifica' : 'Crea' }} stampante {{ currentPrinter?.name }}</h2>
      <form class="row">
        <div class="form-floating col-md-3">
          <input type="text" id="name" v-model="currentPrinter.name" class="form-control">
          <label class="form-label" for="name">nome</label>
        </div>
        <div class="form-floating col-md-3">
          <input type="text" id="queue_name" v-model="currentPrinter.queueName" class="form-control">
          <label class="form-label" for="queue_name">coda</label>
        </div>
        <div class="form-floating col-md-3">
          <input type="text" id="device" v-model="currentPrinter.device" class="form-control">
          <label class="form-label" for="device">dispositivo</label>
        </div>
        <div class="form-floating col-md-3">
          <input type="text" id="bell_times" v-model="currentPrinter.bellTimes" class="form-control">
          <label class="form-label" for="bell_times">suoni</label>
        </div>
        <div class="container">
          <div class="row">
            <div class="col-md-6">
            </div>
            <div class="col-md-3">
              <button class="btn btn-secondary" type="button" @click="close()"><i class="fal fa-times"></i> annulla</button>
            </div>
            <div class="col-md-3">
              <button type="button" :disabled="currentPrinter.name == ''" class="btn btn-primary" @click="save"><i class="fal fa-save"></i> salva</button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import {mapActions, mapState, mapMutations} from "vuex";

export default {
  name: "PrintersForm",
  data() {
    return {
      oldPrinters: [],
      stateClass: 'hidden'
    }
  },

  created() {
    if (localStorage.getItem('printers') != null ) {
      this.oldPrinters = JSON.parse(localStorage.getItem('printers'));
    }
    this.getPrinters({active:1})
        .then(
            () => {
              //console.log(this.printers);
              localStorage.setItem('printers',JSON.stringify(this.printers));
              this.oldPrinters = this.printers;
            }
        )
        .catch(

        );
  },

  mounted() {
    if (localStorage.getItem('printers') != null ) {
      this.oldPrinters = JSON.parse(localStorage.getItem('printers'));
    }
    this.getPrinters({})
        .then(
            () => {
              //console.log(this.printers);
              localStorage.setItem('printers',JSON.stringify(this.printers));
              this.oldPrinters = this.printers;
            }
        )
        .catch(

        );
  },

  methods: {
    ...mapActions({
      updatePrinterById: 'printer/updatePrinterById',
      getPrinters: 'printer/getPrinters'
    }),
    ...mapMutations('core', ['setCurrentPrinter','setCurrentLocation']),

    save() {
      console.log(this.currentPrinter);
      if (!this.currentPrinter?.id) {
        alert("new");
        //return;
      }
      else {
        console.log("about to save");
        this.updatePrinterById(this.currentPrinter).then((data) => {
          if (data.message == 'ok') {
            for (let i = 0; i < this.printers.length; i++) {
              if (this.printers[i].id == this.currentPrinter.id)
                this.printers[i] = this.currentPrinter;
            }
            this.close();
          }
          //if
        });
      }
    },

    close() {
      this.setCurrentPrinter(null);
    },
  },

  computed: {
    ...mapState('printer', ['printers']),
    ...mapState('core', ['currentUser','currentPrinter','currentLocation'])
  }

}
</script>

<style scoped>

</style>