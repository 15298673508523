<template>
  <div id="customers" :class="{ hidden:currentCustomer!=null }">
    <div class="content main box">

      <div class="page-tools">
        <button @click="createNew()" class="btn btn-primary">
          <i class="fal fa-plus"></i> aggiungi
        </button>
      </div>
      <div class="table-wrapper">
        <table class="items-list table table-hover table-striped table-autofit">
          <thead>
          <tr>
            <th>cognome</th>
            <th>nome</th>
            <th>telefono</th>
            <th>email</th>
            <th>provenienza</th>
            <th>data acquisizione</th>
            <th colspan="2">azioni</th>
          </tr>
          </thead>
          <tbody>
          <tr :key="customer.id" v-for="customer in oldCustomers" v-on:click="setCurrentCustomer(customer)">
            <td>{{ customer.lastName }}</td>
            <td>{{ customer.firstName }}</td>
            <td>{{ customer.phone }}</td>
            <td>{{ customer.email }}</td>
            <td>{{ customer.dataSource }}</td>
            <td>{{ customer.createdAt ? (new Date(Date.parse(customer.createdAt))).toLocaleString('it-IT') : '' }}</td>
            <td @click="setCurrentCustomer(customer)"><i class="fal fa-pencil"></i></td>
            <td><i class="fal fa-trash-alt"></i></td>
          </tr>
          </tbody>
          <tfoot></tfoot>
        </table>

      </div>
      <div class="container" :class="total > size ? '' : 'hidden'">
        <div class="row">
          <div class="col-md-2">righe per pagina
            <select class="form-select" name="" id="" v-model="size" @change="getData">
              <option :value="n * 50" :selected="n * 50 == size" v-for="n in 4">{{ n * 50}}</option>
            </select>
          </div>
          <div class="col-md-10">
            <nav aria-label="Page navigation example">
              <ul class="pagination">
                <li class="page-item"><a class="page-link" href="#">&lt;&lt;</a></li>
                <li class="page-item" @click="changePage(i - 1)" :class="{active: i == (page + 1) }" v-for="i in Math.ceil(total/size)"><a class="page-link" href="#">{{ i }}</a></li>
                <!--li class="page-item"><a class="page-link" href="#">2</a></li>
                <li class="page-item"><a class="page-link" href="#">3</a></li-->
                <li class="page-item"><a class="page-link" href="#">&gt;&gt;</a></li>
              </ul>
            </nav>

          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapState, mapMutations} from "vuex";

export default {
  name: "CustomersList",
  data() {
    return {
      page: 0,
      total: 0,
      size: 50,
      currentCustomer: null,
      oldCustomers: [],
      stateClass: 'hidden'
    }
  },

  created() {
    if (localStorage.getItem('customers') != null ) {
      this.oldCustomers = JSON.parse(localStorage.getItem('customers'));
    }
    this.getData();
  },

  mounted() {
    if (localStorage.getItem('customers') != null ) {
      this.oldCustomers = JSON.parse(localStorage.getItem('customers'));
    }
    this.getData();
    /*
    this.getCustomers({size: this.size})
        .then(
            () => {
              //console.log(this.customers);
              localStorage.setItem('customers',JSON.stringify(this.customers));
              this.oldCustomers = this.customers;
            }
        )
        .catch(
        );

     */
  },

  methods: {
    ...mapActions({
      getCustomers: 'customer/getCustomers'
    }),
    ...mapMutations('core', ['setCurrentCustomer','setCurrentLocation']),

    createNew() {
      this.setCurrentCustomer({name:'', color:''});
    },

    changePage(value) {
      this.page = value;
      this.getData();
    },

    getData() {
      this.getCustomers({active:1, size: this.size, page:this.page})
        .then(
          (data) => {
            //console.log(data);
            this.total = data.count;

            localStorage.setItem('customers',JSON.stringify(this.customers));
            this.oldCustomers = this.customers;
          }
        )
        .catch(

        );
    }

  },

  watch: {
    currentLocation: function(val) {
      this.stateClass = val == null? 'hidden':''
    }
  },

  computed: {
    ...mapState('customer', ['customers']),
    ...mapState('core', ['currentUser','currentCustomer','currentLocation'])
  }

}
</script>

<style scoped>

</style>