<template>
  <div>
    <Navigation></Navigation>
    <SupplierOrdersList></SupplierOrdersList>
    <SupplierOrderDraftsForm></SupplierOrderDraftsForm>
  </div>
</template>

<script>
import SupplierOrdersList from "../components/SupplierOrdersList";
import Navigation from "../components/Navigation";
import SupplierOrderDraftsForm from "../components/SupplierOrderDraftsForm";

export default {
  name: "SupplierOrders",
  components: {
    SupplierOrderDraftsForm,
    SupplierOrdersList,
    Navigation
  }
}
</script>

<style scoped>

</style>