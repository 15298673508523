<template>
  <div>
    <Navigation></Navigation>
    <EmployeesList></EmployeesList>
    <EmployeesForm></EmployeesForm>
  </div>
</template>

<script>
import EmployeesList from "../components/EmployeesList";
import EmployeesForm from "../components/EmployeesForm";
import Navigation from "../components/Navigation";

export default {
  name: "Employees",
  components: {
    EmployeesList,
    EmployeesForm,
    Navigation
  }
}
</script>

<style scoped>

</style>