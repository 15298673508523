<template>
  <div>
    <Navigation></Navigation>
    <ProductsList></ProductsList>
    <ProductsForm></ProductsForm>
  </div>
</template>

<script>
import ProductsList from "../components/ProductsList";
import ProductsForm from "../components/ProductsForm";
import Navigation from "../components/Navigation";

export default {
  name: "Products",
  components: {
    ProductsList,
    ProductsForm,
    Navigation
  }
}
</script>

<style scoped>

</style>