<template>
  <div id="printers" :class="{ hidden:currentPrinter!=null }">
    <div class="content main box">

      <div class="page-tools">
        <button @click="createNew()" class="btn btn-primary">
          <i class="fal fa-plus"></i> aggiungi
        </button>
      </div>
      <div class="table-wrapper">
        <table class="items-list table table-hover table-striped table-autofit">
          <thead>
          <tr>
            <th>nome</th>
            <th>coda</th>
            <th>dispositivo</th>
            <th>fiscale</th>
            <th>protocollo</th>
            <th>suono</th>
            <th colspan="3">azioni</th>
          </tr>
          </thead>
          <tbody>
          <tr :key="printer.id" v-for="printer in oldPrinters">
            <td>{{ printer.name }}</td>
            <td>{{ printer.queueName }}</td>
            <td><i class="fal" :class="printer.device.substring(0,1)=='/'? 'fa-usb-drive':'fa-network-wired'"></i> {{ printer.device }}</td>
            <td> <i class="fal" :class="{'fa-euro':printer.fiscal, 'fa-list':!printer.fiscal }"></i></td>
            <td>{{ printer.protocol }}</td>
            <td>{{ printer.bellTimes }}</td>
            <td @click="setCurrentPrinter({...printer})"><i class="fal fa-pencil"></i></td>
            <td @click="testPrinter(printer.id)"><i class="fal fa-paper-plane" title="test"></i></td>
            <td><i class="fal fa-trash-alt"></i></td>
          </tr>
          </tbody>
          <tfoot></tfoot>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapState, mapMutations} from "vuex";

export default {
  name: "PrintersList",
  data() {
    return {
      oldPrinters: [],
      stateClass: 'hidden'
    }
  },

  created() {
    if (localStorage.getItem('printers') != null ) {
      this.oldPrinters = JSON.parse(localStorage.getItem('printers'));
    }
    this.getPrinters({active:1})
        .then(
            () => {
              //console.log(this.printers);
              localStorage.setItem('printers',JSON.stringify(this.printers));
              this.oldPrinters = this.printers;
            }
        )
        .catch(

        );
  },

  mounted() {
    if (localStorage.getItem('printers') != null ) {
      this.oldPrinters = JSON.parse(localStorage.getItem('printers'));
    }
    this.getPrinters({})
        .then(
            () => {
              //console.log(this.printers);
              localStorage.setItem('printers',JSON.stringify(this.printers));
              this.oldPrinters = this.printers;
            }
        )
        .catch(

        );
  },

  methods: {
    ...mapActions({
      getPrinters: 'printer/getPrinters',
      getPrinterById: "printer/getPrinterById",
      testById: "printer/testById"
    }),
    ...mapMutations('core', ['setCurrentPrinter','setCurrentLocation']),

    createNew() {
      this.setCurrentPrinter({name:'', color:''});
    },

    openPrinter(id){
      this.getPrinterById(id).then(() => {
          this.setCurrentPrinter(this.printer);
        }
      )
    },

    testPrinter(id){
      this.testById(id).then((data) => {
        alert('esito test: ' + data.message);
        }
      )
    }

  },

  watch: {
    currentLocation: function(val) {
      this.stateClass = val == null? 'hidden':''
    }
  },

  computed: {
    ...mapState('printer', ['printers','printer']),
    ...mapState('core', ['currentUser','currentPrinter','currentLocation'])
  }

}
</script>

<style scoped>

</style>