<template>
  <div id="statistics">
    <div class="content main box">
      <div class="page-tools container">
        <div class="row">
          <div class="col-1">
            <label>Da</label>
          </div>
          <div class="col-md-2">
            <input v-model="startDate" type="date" class="form-control">
          </div>
          <div class="col-1">
            <label>A</label>
          </div>
          <div class="col-md-2">
            <input v-model="endDate" type="date" class="form-control">
          </div>
          <div class="col-6 form-check form-switch">
            <input class="form-check-input" id="group_by_weekday" @change="sortList" type="checkbox" v-model="groupByWeekday"> <label for="group_by_weekday">raggruppa per giorno della settimana</label>
          </div>
          <div class="col-3">
            <button class="btn btn-primary" @click="applyFilter">filtra</button>
          </div>


          <!--label for="typeId" class="col-md-1">Tipo</label>
          <select v-model="typeId" id="typeId" @change="typeChange" class="col-md-2">
            <option :selected="typeId==1" value="1">materie prime</option>
            <option :selected="typeId==2" value="2">semilavorati</option>
            <option :selected="typeId==3" value="3">prodotti finiti</option>
            <option :selected="typeId==5" value="5">variazioni</option>
          </select>
          <label for="typeId" class="col-md-1">Categoria</label>
          <select v-model="categoryId" id="typeId" @change="categoryChange" class="col-md-2">
            <option value="">--tutte--</option>
            <option :selected="product?.category?.id == category.id" :value="category.id" v-for="category in categories">{{ category.name }}</option>
          </select-->
        </div>
      </div>
      <div v-if="coverCharges > 0">

        <div class="big">
          <i class="fal fa-plate-utensils"></i> {{ coverCharges }}
        </div>

        <i class="fal fa-plate-utensils"></i>

        coperti {{ coverCharges }}  {{ priceRemovedDefaultMultiplier }}

      </div>

      <div class="table-wrapper">
        <table class="items-list table table-hover table-striped table-autofit">
          <thead>
          <tr>
            <th>Reparto</th>
            <th>quantità</th>
            <th>incasso</th>
          </tr>
          </thead>
          <tbody>
            <tr v-for="(count, category, index) of categoryReport.counts">
              <td>{{ category }}</td>
              <td>{{ count }}</td>
              <td>€ {{ categoryReport.totals[category].toFixed(2) }}</td>
            </tr>
          </tbody>
          <tfoot></tfoot>
        </table>
      </div>
      <div class="table-wrapper">
        <table class="items-list table table-hover table-striped table-autofit">
          <thead>
          <tr>
            <th>prod</th>
            <th>q.tà</th>
            <th>paid</th>
            <th>prezzo</th>
            <th>data</th>
            <th colspan="2">azioni</th>
          </tr>
          </thead>
          <tbody>
          <template v-for="order in sales">
          <template v-for="item in order.orderItems">
            <tr>
              <td>{{ item.product.name }}</td>
              <td>{{ item.quantity }}</td>
              <td>{{ item.paid }}</td>
              <td :style=" { color: item.customPrice ? '#f08':'inherit' }">{{ item.customPrice ?? item.subtract? item.product.prices[0].removedPrice: item.product.prices[0].price }}</td>
              <td>{{ item.paidAt }}</td>
              <td class="action-column" data-click="openProduct(product.id)"><i class="fal fa-pencil"></i></td>
              <td class="action-column"><i class="fal fa-trash-alt"></i></td>
            </tr>
            <tr v-for="variation in item.variations">
              <td>{{ variation.subtract ? " - ":" + "}} {{ variation.product.name }}</td>
              <td>{{ variation.quantity }}</td>
              <td>{{ variation.paid }}</td>
              <td :style=" { color: variation.customPrice ? '#f08':'inherit' }">{{ variation.customPrice ?? variation.subtract? variation.product.prices[0].removedPrice: variation.product.prices[0].price }}</td>
              <td>{{ variation.paidAt }}</td>
              <td class="action-column" data-click="openProduct(product.id)"><i class="fal fa-pencil"></i></td>
              <td class="action-column"><i class="fal fa-trash-alt"></i></td>
            </tr>
          </template>
          </template>
          </tbody>
          <tfoot></tfoot>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import printJS from 'print-js';
import {mapActions, mapState, mapMutations} from "vuex";
import rgbHex from 'rgb-hex';

export default {
  name: "StatsPanel",
  data() {
    return {
      groupByWeekday: false,
      coverChargeId: -1,
      priceRemovedDefaultMultiplier: -.1,
      startDate: (new Date(Date.now() - 1000*3600*24)).toISOString().substring(0,10),
      endDate: (new Date(Date.now() - 1000*3600*24)).toISOString().substring(0,10),
      typeId: 1,
      categoryId: null,
      showInactive: false,
      oldProducts: [],
      stateClass: 'hidden',
      coverCharges: 0,
      categoryReport: {
        totals: {},
        counts: {}
      }
    }
  },

  created() {
/*
    if (localStorage.getItem('products') != null ) {
      //this.oldProducts = JSON.parse(localStorage.getItem('products'));
    }
    this.loadProducts();
*/
  },

  mounted() {
    if (localStorage.getItem('statsFilterDateFrom') != null ) {
      //this.oldProducts = JSON.parse(localStorage.getItem('products'));
    }
  },

  methods: {
    ...mapActions({
      getParameter: 'parameter/getParameter',
      getProducts: 'product/getProducts',
      getSales: 'order/getSales',
      getProductById: 'product/getProductById',
      materialsBill: 'product/materialsBill',
    }),
    ...mapMutations('core', ['setCurrentProduct']),

    applyFilter() {
      let payload = {
        startDate: this.startDate + "T03:00:00.000z",
        endDate: new Date(Date.parse(this.endDate + "T03:00:00.000z") +24*3600*1000 ).toISOString()
      }
      this.getSales(payload).then(data => {this.processData(data)});
    },

    typeChange() {
      localStorage.setItem('products_type_id',this.typeId);
      this.loadProducts();
    },

    categoryChange() {
      localStorage.setItem('category_id',this.categoryId);
      this.loadProducts();
    },

    async processData(data) {
      let coverCharges = 0;
      let categoryTotals = {}
      let categoryCounts = {}
      this.coverChargeId = parseInt((await this.getParameter('cover_charge_id')).value);
      this.priceRemovedDefaultMultiplier = parseFloat((await this.getParameter('price_removed_default_multiplier')).value);
      console.log(data);
      data.forEach(order => {
        order.orderItems.forEach(item => {
          let salePrice = item.customPrice ?? item.product.prices[0].price;
          if(item.product.id == this.coverChargeId) {
            coverCharges += item.quantity;
          }
          if (!categoryTotals[item.product.category.name]) {
            categoryTotals[item.product.category.name] = 0;
            categoryCounts[item.product.category.name] = 0;
          }
          categoryTotals[item.product.category.name] += salePrice;
          categoryCounts[item.product.category.name] += item.quantity;
          item.variations.forEach(variation => {
            let salePrice = variation.customPrice ?? (variation.subtract ? variation.product.prices[0].removedPrice : variation.product.prices[0].price);

            categoryTotals[item.product.category.name] += salePrice * item.quantity;

          });

        });
        this.categoryReport.totals = categoryTotals;
        this.categoryReport.counts = categoryCounts;
      });
      this.coverCharges = coverCharges;
    }


  },


  computed: {
    ...mapState('product', ['products','product']),
    ...mapState('category', ['categories']),
    ...mapState('order',['sales']),
    ...mapState('core', ['currentUser','currentProduct'])
  }

}
</script>

<style scoped>

</style>