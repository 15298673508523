<template>
  <div id="locations" :class="{ hidden:currentLocation!=null }">
    <div class="content main box">
      <div class="page-tools">
        <button @click="createNew()" class="btn btn-primary">
          <i class="fal fa-plus"></i> aggiungi
        </button>
      </div>
      <table class="items-list table table-hover table-striped table-autofit">
        <thead>
          <tr>
            <th><i class="fal fa-info-circle"></i></th>
            <th>name</th>
            <th>description</th>
            <th colspan="3">actions</th>
          </tr>
        </thead>
        <tbody>
          <tr :key="location.id" v-for="location in oldLocations" v-on:click="setCurrentLocation(location)">
            <td><i class="fal" :class="location.isTakeaway == 0?'fa-chair':(location.isTakeaway == 1?'fa-shopping-bag':'fa-truck')"></i></td>
            <td>{{ location.name }}</td>
            <td>{{ location.description }}</td>
            <td @click="openMapOrList(location)"><i class="fal" :class="location.isTakeaway == 0?'fa-object-group':'fa-list'"></i></td>
            <td><i class="fal fa-trash-alt"></i></td>
          </tr>
        </tbody>
        <tfoot></tfoot>
      </table>
    </div>
  </div>
</template>

<script>
import {mapActions, mapState, mapMutations} from "vuex";

export default {
  name: "LocationsList",
  data() {
    return {
      oldLocations: [],
      stateClass: 'hidden'
    }
  },

  created() {

    if (localStorage.getItem('locations') != null ) {
      this.oldLocations = JSON.parse(localStorage.getItem('locations'));
    }
    this.getLocations({tree:true})
        .then(
            () => {
              //console.log(this.locations);
              localStorage.setItem('locations',JSON.stringify(this.locations));
              this.oldLocations = this.locations;
            }
        )
        .catch(

        );
  },

  mounted() {
    this.setCurrentLocation(null);
    if (localStorage.getItem('locations') != null ) {
      this.oldLocations = JSON.parse(localStorage.getItem('locations'));
    }
    this.getLocations({tree:true})
        .then(
            () => {
              //console.log(this.locations);
              localStorage.setItem('locations',JSON.stringify(this.locations));
              this.oldLocations = this.locations;
            }
        )
        .catch(

        );
  },

  methods: {
    ...mapActions({
      getLocations: 'location/getLocations'
    }),
    ...mapMutations('core', ['setCurrentLocation','setCurrentLocation','setShowMapOrList']),

    createNew() {
      this.setCurrentLocation({name:'', color:''});
    },

    openMapOrList(location) {
      this.setShowMapOrList(true);
      this.setCurrentLocation(location);
    }

  },


  computed: {
    ...mapState('location', ['locations']),
    ...mapState('core', ['currentUser','currentLocation','currentLocation','showMapOrList'])
  }

}
</script>

<style scoped>

</style>