<template>
  <div>
    <Navigation></Navigation>
    <StatsPanel></StatsPanel>
  </div>
</template>

<script>
import StatsPanel from "../components/StatsPanel";
import Navigation from "../components/Navigation";

export default {
  name: "Statistics",
  components: {
    StatsPanel,
    Navigation
  }
}
</script>

<style scoped>

</style>