<template>
  <div id="tools"  class="box" :class="{hidden:!currentUser}">
    <div class="content">
      <aside>
        <nav class="navbar navbar-expand-lg navbar-light">
          <div class="container-fluid">
            <h2 class="navbar-brand logo" href="#"><span class="brand-color-1">Risto</span><span class="brand-color-2">Matico</span></h2>
            <button class="navbar-toggler collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span class="navbar-toggler-icon"></span><i class="fal fa-times"></i><i class="fal fa-bars"></i>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
              <ul class="main-menu navbar-nav me-auto mb-2 mb-lg-0">
                <li v-if="currentUser?.level < 2" class="nav-item">
                  <a class="nav-link" :class="{active: '/' == $route.fullPath }" aria-current="page" :href="'/'"><i class="fal fa-gauge-high"></i> Dashboard</a>
                </li>
                <template v-for="item in menuItems.filter(el => el.userLevel >= currentUser?.level)">
                  <li class="nav-item">
                    <a class="nav-link" :href="item.url" :class="{active: item.url == $route.fullPath }"><i :class="item.icon"></i> {{ item.name }}</a>
                  </li>
                </template>
                <!--              <li class="nav-item dropdown">
                                <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                  Dropdown
                                </a>
                                <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                                  <li><a class="dropdown-item" href="#">Action</a></li>
                                  <li><a class="dropdown-item" href="#">Another action</a></li>
                                  <li><hr class="dropdown-divider"></li>
                                  <li><a class="dropdown-item" href="#">Something else here</a></li>
                                </ul>
                              </li>
                              <li class="nav-item">
                                <a class="nav-link disabled" href="#" tabindex="-1" aria-disabled="true">Disabled</a>
                              </li>-->
              </ul>
              <div id="user" v-if="currentUser">
                <i class="fal fa-user-headset"></i> {{ currentUser.name }}
                <button v-on:click="quit">
                  <i class="fal fa-power-off"></i>
                </button>
              </div>
              <!--form class="d-flex">
                <input class="form-control me-2" type="search" placeholder="Search" aria-label="Search">
                <button class="btn btn-outline-success" type="submit">Search</button>
              </form-->
            </div>
          </div>
        </nav>

      </aside>

    </div>
<!--    <div class="content">
      <aside>
        <nav class="navbar navbar-expand-lg navbar-light">
          <div class="container-fluid">
            <a class="navbar-brand" href="#">Ristopos</a>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
              <ul class="main-menu navbar-nav me-auto mb-2 mb-lg-0">
                <li class="nav-item">
                  <a class="nav-link active" aria-current="page" href="#">Home</a>
                </li>
                <li class="nav-item" v-for="item in menuItems">
                  <a class="nav-link" :href="item.url"><i :class="item.icon"></i> {{ item.name }}</a>
                </li>

              </ul>

            </div>
          </div>
        </nav>

      </aside>

      <div id="user" v-if="currentUser">
        <i class="fal fa-user-headset"></i> {{ currentUser.name }}
      </div>
      <div id="logout-btn" v-if="currentUser">
        <button v-on:click="quit">
          <i class="fal fa-power-off"></i>
        </button>
      </div>
    </div>-->
  </div>
</template>

<script>
import {mapActions, mapMutations, mapState} from "vuex";
import VueRouter from 'vue-router';

export default {
  name: "Locations",

  data() {
    return {
      menuItems: [
        {
          name: 'statistiche',
          url: '/statistics',
          icon: 'fal fa-chart-mixed',
          userLevel: 1
        },
        {
          name: 'categorie',
          url: '/categories',
          icon: 'fal fa-folder-tree',
          userLevel: 2
        },
        {
          name: 'prodotti',
          url: '/products',
          icon: 'fal fa-pizza-slice',
          userLevel: 2
        },
        {
          name: 'sale',
          url: '/locations',
          icon: 'fal fa-chair',
          userLevel: 2
        },
        {
          name: 'inventario',
          url: '/warehouse',
          icon: 'fal fa-warehouse-full',
          userLevel: 2
        },
        {
          name: 'fornitori',
          url: '/suppliers',
          icon: 'fal fa-truck-field',
          userLevel: 1
        },
        {
          name: 'ordini',
          url: '/supplier-orders',
          icon: 'fal fa-cart-shopping',
          userLevel: 1
        },
        {
          name: 'clienti',
          url: '/customers',
          icon: 'fal fa-users',
          userLevel: 1
        },
        {
          name: 'collaboratori',
          url: '/employees',
          icon: 'fal fa-user-tie',
          userLevel: 1
        },
        {
          name: 'turni di lavoro',
          url: '/workshifts',
          icon: 'fal fa-business-time',
          userLevel: 4
        },
        {
          name: 'stampanti',
          url: '/printers',
          icon: 'fal fa-print',
          userLevel: 1
        },
        {
          name: 'parametri',
          url: '/parameters',
          icon: 'fal fa-cogs',
          userLevel: 1
        }
      ],
      offLine: false,
      currentLocationString: '',
      tableClass: 'fa-chair',
      stateClass: this.currentUser == null? 'hidden':''
    }
  },

  created() {
    //setInterval(this.checkNetwork,10000);
  },

  methods: {
    ...mapMutations({
      setCurrentLocation: 'core/setCurrentLocation'
    }),

    ...mapActions({
      logout: 'core/logout'
    }),

    quit () {
      this.logout();
      this.$router.push('/logout?time='+ (new Date()).getTime());
    }

  },

  watch: {
    location: function(val) {
      switch (val?.isTakeaway) {
        case null:
        case 0:
          this.tableClass = 'fa-chair';
          break;
        case 1:
          this.tableClass = 'fa-shopping-bag';
          break;
        case 2:
          this.tableClass = 'fa-truck';
          break;
      }
    }
  },

  computed: {
    ...mapState('product', ['products']),
    ...mapState('core', ['currentUser','currentLocation']),
    ...mapState('location', ['location'])
  }


}
</script>

<style scoped>

</style>