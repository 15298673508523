<template>
  <div>
    <Navigation></Navigation>
    <WorkshiftsCalendar></WorkshiftsCalendar>
    <WorkshiftsStamp :class="{hidden: activeComponent != 'stamper'}"></WorkshiftsStamp>
<!--
    <WorkshiftsHistory :class="{hidden: activeComponent != 'list'}"></WorkshiftsHistory>
-->
    <button :class="activeComponent == 'calendar'?'active':''" class="calendar-toggler" @click="activeComponent = 'calendar'"><i class="fal fa-calendar-days"></i></button>
    <button :class="activeComponent == 'stamper'?'active':''" class="stamp-toggler" @click="activeComponent = 'stamper'"><i class="fal fa-stamp"></i></button>
<!--
    <button :class="activeComponent == 'list'?'active':''" class="history-toggler" @click="activeComponent = 'list'"><i class="fal fa-history"></i></button>
-->
  </div>
</template>

<script>
import WorkshiftsCalendar from "../components/WorkshiftsCalendar";
import WorkshiftsStamp from "../components/WorkshiftsStamp.vue";
//import WorkshiftsHistory from "../components/WorkshiftsHistory.vue";
import Navigation from "../components/Navigation";

export default {
  name: "Workshifts",
  data() {
    return {
      activeComponent: 'calendar'
    }
  },
  components: {
    WorkshiftsCalendar,
    WorkshiftsStamp,
    //WorkshiftsHistory,
    Navigation
  }
}
</script>

<style scoped>

</style>