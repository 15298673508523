<template>
  <div id="employees-form" class="dialog" :class="{ hidden:currentEmployee==null }">
    <div class="content box">
      <form v-if="currentEmployee">
        <div class="form-check form-switch">
          <input type="checkbox" id="active" :value="currentEmployee.active" name="active" class="form-check-input">
          <label class="form-check-label" for="active">active</label>
        </div>
        <div class="form-floating">
          <input type="text" id="name" :value="currentEmployee.name" name="name" class="form-control">
          <label class="form-label" for="name">name</label>
        </div>
        <div class="form-floating">
          <input type="color" id="color" :value="currentEmployee.color" name="color" class="form-control">
          <label class="form-label" for="name">color</label>
        </div>
        <div class="form-floating">
          <input type="text" id="name" :value="currentEmployee.name" name="name" class="form-control">
          <label class="form-label" for="name">name</label>
        </div>
        <div class="form-floating">
          <input type="text" id="name" :value="currentEmployee.name" name="name" class="form-control">
          <label class="form-label" for="name">name</label>
        </div>
        <div class="container">
          <div class="row">
            <div class="col-md-6">
            </div>
            <div class="col-md-3">
              <button class="btn btn-secondary" type="button" @click="close()"><i class="fal fa-times"></i> cancel</button>
            </div>
            <div class="col-md-3">
              <button class="btn btn-primary"><i class="fal fa-save"></i> salva</button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import {mapActions, mapState, mapMutations} from "vuex";

export default {
  name: "EmployeesForm",
  data() {
    return {
      oldEmployees: [],
      stateClass: 'hidden'
    }
  },

  created() {
    if (localStorage.getItem('employees') != null ) {
      this.oldEmployees = JSON.parse(localStorage.getItem('employees'));
    }
    this.getEmployees({active:1})
        .then(
            () => {
              //console.log(this.employees);
              localStorage.setItem('employees',JSON.stringify(this.employees));
              this.oldEmployees = this.employees;
            }
        )
        .catch(

        );
  },

  mounted() {
    if (localStorage.getItem('employees') != null ) {
      this.oldEmployees = JSON.parse(localStorage.getItem('employees'));
    }
    this.getEmployees({})
        .then(
            () => {
              //console.log(this.employees);
              localStorage.setItem('employees',JSON.stringify(this.employees));
              this.oldEmployees = this.employees;
            }
        )
        .catch(

        );
  },

  methods: {
    ...mapActions({
      getEmployees: 'employee/getEmployees'
    }),
    ...mapMutations('core', ['setCurrentEmployee','setCurrentLocation']),

    close() {
      this.setCurrentEmployee(null);
    },



  },

  computed: {
    ...mapState('employee', ['employees']),
    ...mapState('core', ['currentUser','currentEmployee','currentLocation'])
  }

}
</script>

<style scoped>

</style>