<template>
  <div id="employees" :class="{ hidden:currentEmployee!=null }">
    <div class="content main box">

      <div class="page-tools container">
        <div class="row">
          <button @click="createNew()" class="btn btn-primary col-md-1">
            <i class="fal fa-plus"></i> aggiungi
          </button>
          <div class="col-md-1"></div>
          <button @click="setShowInactive(!showInactive)" class="btn btn-secondary col-md-1">
            <i class="fal" :class="showInactive?'fa-eye':'fa-eye-slash'"></i> inattivi
          </button>
        </div>
      </div>
      <div class="table-wrapper">
        <table class="items-list table table-hover table-striped table-autofit">
          <thead>
            <tr>
              <th>cognome</th>
              <th>nome</th>
              <th>in breve</th>
              <th>email</th>
              <th>telefono</th>
              <th>attivo</th>
              <th>colore</th>
              <th colspan="2">azioni</th>
            </tr>
          </thead>
          <tbody>
            <tr :class="employee.active?'':'inactive'" :key="employee.id" v-for="employee in oldEmployees.filter(el => showInactive? true: el.active == true )">
              <td>{{ employee.lastName }}</td>
              <td>{{ employee.firstName }}</td>
              <td>{{ employee.nickname }}</td>
              <td><a :href="'mailto:' + employee.email">{{ employee.email }}</a></td>
              <td><a :href="'tel:' + employee.phone">{{ employee.phone }}</a></td>
              <td class="active-column"><i class="fal" :class="'fa-lightbulb' + (employee.active ? '-on':'' )"></i><span class="value">{{ employee.active }}</span></td>
              <td class="color-column" :style="{backgroundColor: employee.color}">{{ employee.color }}</td>
              <td class="action-column" @click="setCurrentEmployee(employee)"><i class="fal fa-pencil"></i></td>
              <td class="action-column"><i class="fal fa-trash-alt"></i></td>
            </tr>
          </tbody>
          <tfoot></tfoot>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapState, mapMutations} from "vuex";

export default {
  name: "EmployeesList",
  data() {
    return {
      oldEmployees: [],
      showInactive: false,
      stateClass: 'hidden'
    }
  },

  created() {
/*
    if (localStorage.getItem('employees') != null ) {
      this.oldEmployees = JSON.parse(localStorage.getItem('employees'));
    }
    this.getEmployees({active:1})
        .then(
            () => {
              //console.log(this.employees);
              localStorage.setItem('employees',JSON.stringify(this.employees));
              this.oldEmployees = this.employees;
            }
        )
        .catch(

        );
*/
  },

  mounted() {
    if (localStorage.getItem('employees') != null ) {
      this.oldEmployees = JSON.parse(localStorage.getItem('employees'));
    }
    if (localStorage.getItem('employees_active') != null ) {
      this.showInactive = JSON.parse(localStorage.getItem('employees_active')) == true;
    }
    this.setShowInactive(this.showInactive);


  },

  methods: {
    ...mapActions({
      getEmployees: 'employee/getEmployees'
    }),
    ...mapMutations('core', ['setCurrentEmployee','setCurrentLocation']),

    createNew() {
      this.setCurrentEmployee({name:'', color:''});
    },

    loadEmployees() {
      let payload = {};

      if (!this.showInactive) {
        payload = {active:1};
      }

      this.getEmployees(payload)
        .then(
          () => {
            //console.log(this.employees);
            localStorage.setItem('employees',JSON.stringify(this.employees));
            this.oldEmployees = this.employees;
          }
        )
        .catch(

        );
    },

    setShowInactive(show) {
      this.showInactive = show;
      localStorage.setItem('employees_active',show);
      //this.loadEmployees();
    }

  },

  watch: {
    currentLocation: function(val) {
      this.stateClass = val == null? 'hidden':''
    }
  },

  computed: {
    ...mapState('employee', ['employees']),
    ...mapState('core', ['currentUser','currentEmployee','currentLocation'])
  }

}
</script>

<style scoped>

</style>