<template>
  <div id="supplier-products">
    <div class="content main box">

      <div class="page-tools">
        <button @click="createNew()" class="btn btn-primary">
          <i class="fal fa-plus"></i>aggiungi
        </button>
      </div>
      <div class="table-wrapper">
        <table class="items-list table table-hover table-striped table-autofit">
          <thead>
          <tr>
            <th>materia prima</th>
            <th v-if="!supplierId">Fornitore</th>
            <th>codice</th>
            <th>prezzo</th>
            <th>valido dal</th>
            <th colspan="2">azioni</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="supplierProduct in oldSupplierProducts">
            <td>{{ supplierProduct.product.name }}</td>
            <td v-if="!supplierId">{{ supplierProduct.supplier.displayName }}</td>
            <td>{{ supplierProduct.code }}</td>
            <td>€ {{ supplierProduct.product.costs[0]?.price.toFixed(2)??'-.--' }}/{{ supplierProduct.product?.unit?.measure }}</td>
            <td>{{supplierProduct.product.costs[0]?.dateFrom.slice(0,10).split('-').reverse().join('/')??'--/--/----' }}</td>
            <td @click="setCurrentSupplier(supplier)"><i class="fal fa-pencil"></i></td>
            <td><i class="fal fa-trash-alt"></i></td>
          </tr>
          </tbody>
          <tfoot></tfoot>
        </table>

      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapState, mapMutations} from "vuex";
import router from "@/router";
import supplierProduct from "../store/modules/supplierProduct";

export default {
  name: "SuppliersProductsList",
  props: ['supplierId'],
  data() {
    return {
      oldSupplierProducts: [],
      stateClass: 'hidden'
    }
  },

  created() {

    //this.supplierId = $route.params.supplierId;

    if (localStorage.getItem('supplierProducts') != null ) {
      this.oldSupplierProducts = JSON.parse(localStorage.getItem('supplierProducts'));
    }
    this.getSupplierProducts({ supplierId: this.supplierId })
        .then(
            (data) => {
              console.log(data);
              console.log(this.supplierProducts);
              localStorage.setItem('supplierProducts',JSON.stringify(this.supplierProducts??null));
              this.oldSupplierProducts = this.supplierProducts;
            }
        )
        .catch(

        );


  },

  mounted() {
    /*
    console.log('mounted');
    if (localStorage.getItem('suppliers') != null ) {
      this.oldSuppliers = JSON.parse(localStorage.getItem('suppliers'));
    }
    this.getSuppliers({})
        .then(
            () => {
              //console.log(this.suppliers);
              localStorage.setItem('suppliers',JSON.stringify(this.suppliers));
              this.oldSuppliers = this.suppliers;
            }
        )
        .catch(

        );

     */
  },

  methods: {
    router() {
      return router
    },
    ...mapActions({
      getSupplierProducts: 'supplierProduct/getSupplierProducts'
    }),
    ...mapMutations('core', ['setCurrentSupplier','setCurrentLocation']),

    createNew() {
      this.setCurrentSupplier({name:'', color:''});
    }

  },

  computed: {
    supplierProduct() {
      return supplierProduct
    },
    ...mapState('supplierProduct', ['supplierProducts']),
    ...mapState('core', ['currentUser','currentSupplier','currentLocation'])
  }

}
</script>

<style scoped>

</style>