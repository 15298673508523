<template>
  <div>
    <Navigation></Navigation>
    <SuppliersList></SuppliersList>
    <SuppliersForm></SuppliersForm>
  </div>
</template>

<script>
import SuppliersList from "../components/SuppliersList";
import SuppliersForm from "../components/SuppliersForm";
import Navigation from "../components/Navigation";

export default {
  name: "Suppliers",
  components: {
    SuppliersList,
    SuppliersForm,
    Navigation
  }
}
</script>

<style scoped>

</style>