<template>
  <div class="row">
    <div class="form-floating col-md-8">
      <input type="file" id="file" ref="file" v-on:change="onChangeFileUpload()" class="form-control">
    </div>
    <div class="col-md-4">
      <button class="btn btn-primary" v-on:click="submitForm()">Carica <i class="fal fa-file-upload"></i></button>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  data () {
    return {
      file: '',
      fullName: ''
    }
  },

  methods: {
    ...mapActions({
      uploadFile: 'parameter/uploadFile'
    }),

    submitForm () {
      let formData = new FormData()
      formData.append('file', this.file)

      this.uploadFile(formData)
        .then((data) => {
          if (data.uploads) {
            if (data.uploads[0].done) {
              console.log(data.uploads[0])
              this.fullName = data.uploads[0].fullName
              this.$emit('uploaded', this.fullName)
              // alert(this.fullName);
            }
          }
          // console.log(data);
        })
        .catch((err) => {
          alert('Errore nel caricamento del file: ' + err)
        })
    },

    onChangeFileUpload () {
      this.file = this.$refs.file.files[0]
    }
  }
}
</script>
