<template>
  <div>
    <Navigation></Navigation>
    <LocationsList></LocationsList>
    <LocationsForm></LocationsForm>
    <LocationsMap></LocationsMap>
  </div>
</template>

<script>
import LocationsList from "../components/LocationsList";
import LocationsForm from "../components/LocationsForm";
import LocationsMap from "../components/LocationsMap";
import Navigation from "../components/Navigation";

export default {
  name: "Locations",
  components: {
    LocationsList,
    LocationsForm,
    LocationsMap,
    Navigation
  }
}
</script>

<style scoped>

</style>