<template>
  <div id="suppliers-form" :class="{ hidden:currentSupplier==null }">
    <div class="content main box">
      <form v-if="currentSupplier">
        <div class="form-check form-switch">
          <input type="checkbox" id="active" value="1" :checked="currentSupplier.active" name="active" class="form-check-input">
          <label class="form-check-label" for="active">active</label>
        </div>
        <div class="form-floating">
          <input type="text" id="company_name" :value="currentSupplier.companyName" name="company_name" class="form-control">
          <label class="form-label" for="name">Ragione Sociale</label>
        </div>
        <div class="form-floating">
          <input type="text" id="display_name" :value="currentSupplier.displayName" name="display_name" class="form-control">
          <label class="form-label" for="display_name">Nome visualizzato</label>
        </div>
        <div class="form-floating">
          <input type="text" id="phone" :value="currentSupplier.phone" name="phone" class="form-control">
          <label class="form-label" for="phone">Telefono</label>
        </div>
        <div class="form-floating">
          <input type="text" id="mobile" :value="currentSupplier.mobile" name="mobile" class="form-control">
          <label class="form-label" for="mobile">Cellulare</label>
        </div>
        <div class="form-floating">
          <input type="text" id="fax" :value="currentSupplier.fax" name="mobile" class="form-control">
          <label class="form-label" for="fax">Fax</label>
        </div>
        <div class="form-floating">
          <input type="email" id="email" :value="currentSupplier.email" name="email" class="form-control">
          <label class="form-label" for="email">Email</label>
        </div>
        <div class="form-floating">
          <input type="text" id="pec" :value="currentSupplier.pec" name="pec" class="form-control">
          <label class="form-label" for="pec">P.E.C.</label>
        </div>
        <div class="form-floating">
          <input type="text" id="referent" :value="currentSupplier.referent" name="referent" class="form-control">
          <label class="form-label" for="referent">Referente</label>
        </div>
        <div class="form-floating">
          <input type="text" id="iban" :value="currentSupplier.iban" name="iban" class="form-control">
          <label class="form-label" for="iban">Iban</label>
        </div>
        <div class="container">
          <div class="row">
            <div class="col-md-6">
            </div>
            <div class="col-md-3">
              <button class="btn btn-secondary" type="button" @click="close()"><i class="fal fa-times"></i> cancel</button>
            </div>
            <div class="col-md-3">
              <button class="btn btn-primary"><i class="fal fa-save"></i> salva</button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import {mapActions, mapState, mapMutations} from "vuex";

export default {
  name: "SuppliersForm",
  data() {
    return {
      oldSuppliers: [],
      stateClass: 'hidden'
    }
  },

  created() {
    if (localStorage.getItem('suppliers') != null ) {
      this.oldSuppliers = JSON.parse(localStorage.getItem('suppliers'));
    }
    this.getSuppliers({active:1})
        .then(
            () => {
              //console.log(this.suppliers);
              localStorage.setItem('suppliers',JSON.stringify(this.suppliers));
              this.oldSuppliers = this.suppliers;
            }
        )
        .catch(

        );
  },

  mounted() {
    if (localStorage.getItem('suppliers') != null ) {
      this.oldSuppliers = JSON.parse(localStorage.getItem('suppliers'));
    }
    this.getSuppliers({})
        .then(
            () => {
              //console.log(this.suppliers);
              localStorage.setItem('suppliers',JSON.stringify(this.suppliers));
              this.oldSuppliers = this.suppliers;
            }
        )
        .catch(

        );
  },

  methods: {
    ...mapActions({
      getSuppliers: 'supplier/getSuppliers'
    }),
    ...mapMutations('core', ['setCurrentSupplier']),

    close() {
      this.setCurrentSupplier(null);
    },

    keyPressed(event) {
      switch(event.code) {
        case 'Escape':
          this.close();
          break;
      }
      event.stopImmediatePropagation();
    },


  },

  computed: {
    ...mapState('supplier', ['suppliers']),
    ...mapState('core', ['currentUser','currentSupplier'])
  }

}
</script>

<style scoped>

</style>