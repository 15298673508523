<template>
  <div id="suppliers" :class="{ hidden:currentSupplier!=null }">
    <div class="content main box">

      <div class="page-tools container">
        <div class="row">
          <button @click="createNew()" class="btn btn-primary">
            <i class="fal fa-plus"></i> aggiungi
          </button>
          <div class="col-md-1"></div>
          <button @click="setShowInactive(!showInactive)" class="btn btn-secondary col-md-1">
            <i class="fal" :class="showInactive?'fa-eye':'fa-eye-slash'"></i> inattivi
          </button>
        </div>
      </div>
      <div class="table-wrapper">
        <table class="items-list table table-hover table-striped table-autofit">
          <thead>
          <tr>
            <th>ragione sociale</th>
            <th>nome visualizzato</th>
            <th>telefono</th>
            <th>cellulare</th>
            <th>email</th>
            <th>pec</th>
            <th>iban</th>
            <th colspan="3">azioni</th>
          </tr>
          </thead>
          <tbody>
          <tr :class="supplier.active?'':'inactive'" :key="supplier.id" v-for="supplier in oldSuppliers">
            <td>{{ supplier.companyName }}</td>
            <td>{{ supplier.displayName }}</td>
            <td>{{ supplier.phone }}</td>
            <td>{{ supplier.mobile }}</td>
            <td>{{ supplier.email }}</td>
            <td>{{ supplier.pec }}</td>
            <td>{{ supplier.iban }}</td>
            <td @click="setCurrentSupplier({...supplier})"><i class="fal fa-pencil"></i></td>
            <td @click="router().push('/supplier-products/'+supplier.id)"><i class="fal fa-list"></i></td>
            <td><i class="fal fa-trash-alt"></i></td>
          </tr>
          </tbody>
          <tfoot></tfoot>
        </table>

      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapState, mapMutations} from "vuex";
import router from "@/router";

export default {
  name: "SuppliersList",
  props: {
    supplierId: String,
  },
  data() {
    return {
      showInactive: true,
      oldSuppliers: [],
      stateClass: 'hidden'
    }
  },

  created() {
    if (localStorage.getItem('suppliers') != null ) {
      this.oldSuppliers = JSON.parse(localStorage.getItem('suppliers'));
    }
    this.getSuppliers({active:1})
        .then(
            () => {
              //console.log(this.suppliers);
              localStorage.setItem('suppliers',JSON.stringify(this.suppliers));
              this.oldSuppliers = this.suppliers;
            }
        )
        .catch(

        );
  },

  mounted() {
    if (localStorage.getItem('suppliers_active') != null ) {
      this.showInactive = JSON.parse(localStorage.getItem('suppliers_active')) == true;
    }
    this.setShowInactive(this.showInactive);
    this.loadSuppliers()
  },

  methods: {
    router() {
      return router
    },
    ...mapActions({
      getSuppliers: 'supplier/getSuppliers'
    }),
    ...mapMutations('core', ['setCurrentSupplier','setCurrentLocation']),

    createNew() {
      this.setCurrentSupplier({name:'', color:''});
    },

    loadSuppliers() {
      if (localStorage.getItem('suppliers') != null ) {
        this.oldSuppliers = JSON.parse(localStorage.getItem('suppliers'));
      }
      this.getSuppliers({})
        .then(
          () => {
            //console.log(this.suppliers);
            localStorage.setItem('suppliers',JSON.stringify(this.suppliers));
            this.oldSuppliers = this.suppliers;
          }
        )
        .catch(

        );

    },

    setShowInactive(show) {
      this.showInactive = show;
      localStorage.setItem('suppliers_active',show);
      this.loadSuppliers();
    }


  },

  computed: {
    ...mapState('supplier', ['suppliers']),
    ...mapState('core', ['currentUser','currentSupplier','currentLocation'])
  }

}
</script>

<style scoped>

</style>