<template>
  <div id="customers-form" class="dialog" :class="{ hidden:currentCustomer==null }">
    <div class="content box">
      <form v-if="currentCustomer">
        <div class="form-check form-switch">
          <input type="checkbox" id="active" :value="currentCustomer.active" name="active" class="form-check-input">
          <label class="form-check-label" for="active">active</label>
        </div>
        <div class="form-floating">
          <input type="text" id="name" :value="currentCustomer.name" name="name" class="form-control">
          <label class="form-label" for="name">name</label>
        </div>
        <div class="form-floating">
          <input type="color" id="color" :value="currentCustomer.color" name="color" class="form-control">
          <label class="form-label" for="name">color</label>
        </div>
        <div class="form-floating">
          <input type="text" id="name" :value="currentCustomer.name" name="name" class="form-control">
          <label class="form-label" for="name">name</label>
        </div>
        <div class="form-floating">
          <input type="text" id="name" :value="currentCustomer.name" name="name" class="form-control">
          <label class="form-label" for="name">name</label>
        </div>
        <div class="container">
          <div class="row">
            <div class="col-md-6">
            </div>
            <div class="col-md-3">
              <button class="btn btn-secondary" type="button" @click="close()"><i class="fal fa-times"></i> cancel</button>
            </div>
            <div class="col-md-3">
              <button class="btn btn-primary"><i class="fal fa-save"></i> salva</button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import {mapActions, mapState, mapMutations} from "vuex";

export default {
  name: "CustomersForm",
  data() {
    return {
      currentCustomer: null,
      oldCustomers: [],
      stateClass: 'hidden'
    }
  },

  created() {
    if (localStorage.getItem('customers') != null ) {
      this.oldCustomers = JSON.parse(localStorage.getItem('customers'));
    }
    this.getCustomers({active:1})
        .then(
            () => {
              //console.log(this.customers);
              localStorage.setItem('customers',JSON.stringify(this.customers));
              this.oldCustomers = this.customers;
            }
        )
        .catch(

        );
  },

  mounted() {
    if (localStorage.getItem('customers') != null ) {
      this.oldCustomers = JSON.parse(localStorage.getItem('customers'));
    }
    this.getCustomers({})
        .then(
            () => {
              //console.log(this.customers);
              localStorage.setItem('customers',JSON.stringify(this.customers));
              this.oldCustomers = this.customers;
            }
        )
        .catch(

        );
  },

  methods: {
    ...mapActions({
      getCustomers: 'customer/getCustomers'
    }),
    ...mapMutations('core', ['setCurrentCustomer','setCurrentLocation'])
  },

  computed: {
    ...mapState('customer', ['customers']),
    ...mapState('core', ['currentUser','currentCustomer','currentLocation'])
  }

}
</script>

<style scoped>

</style>