<template>
  <div id="categories-form" class="dialog" :class="{ hidden:currentCategory==null }">
    <div class="content box">
      <form v-if="currentCategory">
        <div class="form-check form-switch">
          <input type="checkbox" id="active" :value="currentCategory.active" name="active" class="form-check-input">
          <label class="form-check-label" for="active">active</label>
        </div>
        <div class="form-floating">
          <input type="text" id="name" :value="currentCategory.name" name="name" class="form-control">
          <label class="form-label" for="name">name</label>
        </div>
        <div class="form-floating">
          <input type="color" id="color" :value="currentCategory.color" name="color" class="form-control">
          <label class="form-label" for="name">color</label>
        </div>
        <div class="form-floating">
          <input type="text" id="name" :value="currentCategory.name" name="name" class="form-control">
          <label class="form-label" for="name">name</label>
        </div>
        <div class="form-floating">
          <input type="text" id="name" :value="currentCategory.name" name="name" class="form-control">
          <label class="form-label" for="name">name</label>
        </div>
        <div class="container">
          <div class="row">
            <div class="col-md-6">
            </div>
            <div class="col-md-3">
              <button class="btn btn-secondary"><i class="fal fa-times"></i> cancel</button>
            </div>
            <div class="col-md-3">
              <button class="btn btn-primary"><i class="fal fa-save"></i> salva</button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import {mapActions, mapState, mapMutations} from "vuex";

export default {
  name: "CategoriesForm",
  data() {
    return {
      oldCategories: [],
      stateClass: 'hidden'
    }
  },

  created() {
    if (localStorage.getItem('categories') != null ) {
      this.oldCategories = JSON.parse(localStorage.getItem('categories'));
    }
    this.getCategories({active:1})
        .then(
            () => {
              //console.log(this.categories);
              localStorage.setItem('categories',JSON.stringify(this.categories));
              this.oldCategories = this.categories;
            }
        )
        .catch(

        );
  },

  mounted() {
    if (localStorage.getItem('categories') != null ) {
      this.oldCategories = JSON.parse(localStorage.getItem('categories'));
    }
    this.getCategories({})
        .then(
            () => {
              //console.log(this.categories);
              localStorage.setItem('categories',JSON.stringify(this.categories));
              this.oldCategories = this.categories;
            }
        )
        .catch(

        );
  },

  methods: {
    ...mapActions({
      getCategories: 'category/getCategories'
    }),
    ...mapMutations('core', ['setCurrentCategory','setCurrentLocation'])
  },

  watch: {
    currentLocation: function(val) {
      this.stateClass = val == null? 'hidden':''
    }
  },

  computed: {
    ...mapState('category', ['categories']),
    ...mapState('core', ['currentUser','currentCategory','currentLocation'])
  }

}
</script>

<style scoped>

</style>