<template>
  <div id="products-form" class="dialog" :class="{ hidden:currentProduct==null }">
    <div class="content box container" v-if="currentProduct!=null">
      <h2 class="title"> {{ currentProduct?.id ? 'Modifica' : 'Crea' }} prodotto {{ currentProduct?.name }}</h2>
      <form class="row">
        <div class="form-floating mb-3 col-md-3">
          <input type="text" id="name" v-model="currentProduct.name" class="form-control" placeholder="nome">
          <label class="form-label" for="name">nome</label>
        </div>
        <div class="form-floating mb-3 col-md-2">
          <select id="typeId" v-model="currentProduct.productTypeId" class="form-select" placeholder="tipo">
            <option value=""></option>
            <option v-for="productType in productTypes" :key="productType.id" :value="productType.id" :selected="productType.id == currentProduct?.productType?.id">{{ productType.name }}</option>
          </select>
          <label class="form-label" for="typeId">tipo</label>
        </div>
        <div class="form-floating mb-3 col-md-2">
          <select id="typeId" v-model="currentProduct.unitId" class="form-select" placeholder="tipo">
            <option value=""></option>
            <option v-for="unit in units" :key="unit.id" :value="unit.id" :selected="unit.id == currentProduct.unitId">{{ unit.measure }}</option>
          </select>
          <label class="form-label" for="unitId">unità di misura</label>
        </div>
        <div class="form-floating mb-3" :class="currentProduct?.productTypeId != 3 && currentProduct?.productTypeId != 5?'col-md-3':'col-md-2'">
          <select id="categoryId" name="categoryId" class="form-select" placeholder="categoria">
            <option value=""></option>
            <option v-for="category in categories" :key="category.id" :value="category.id" :selected="category.id == currentProduct?.category?.id">{{ category.name }}</option>
          </select>
          <label class="form-label" for="categoryId">categoria</label>
        </div>
        <div class="form-floating mb-3 col-md-1" :class="{hidden:currentProduct?.productTypeId != 3 && currentProduct?.productTypeId != 5}">
          <input type="color" id="color" @input="event => currentProduct.color = event.target.value" :value="hexColor(currentProduct.color)" name="color" class="form-control" placeholder="color">
          <label class="form-label" for="color">color</label>
        </div>
        <div class="form-check form-switch mb-3 col-md-2">
          <input type="checkbox" id="active" v-model="currentProduct.active" class="form-control form-check-input">
          <label class="form-check-label" for="active"><i class="fal fa-lightbulb-on"></i> attivo</label>
        </div>
        <div class="form-check form-switch mb-3 col-md-2" :class="{hidden:currentProduct?.productTypeId != 1 && currentProduct?.productTypeId != 2 && currentProduct?.productTypeId != 3}">
          <input type="checkbox" id="stockManaged" v-model="currentProduct.stockManaged" class="form-control form-check-input">
          <label class="form-check-label" for="stockManaged"><i class="fal fa-people-carry"></i> gestione stock</label>
        </div>
        <div class="form-check form-switch mb-3 col-md-2" :class="{hidden:currentProduct?.productTypeId != 3 && currentProduct?.productTypeId != 5}">
          <input type="checkbox" id="showButton" v-model="currentProduct.showButton" class="form-control form-check-input">
          <label class="form-check-label" for="showButton"><i class="fal fa-light-switch fa-rotate-90"></i> mostra bottone</label>
        </div>
        <div class="form-check form-switch mb-3 col-md-2" :class="{hidden:currentProduct?.productTypeId != 3 && currentProduct?.productTypeId != 5}">
          <input type="checkbox" id="webVisible" v-model="currentProduct.webVisible" class="form-control form-check-input">
          <label class="form-check-label" for="webVisible"><i class="fal fa-mouse-pointer"></i> mostra sul sito</label>
        </div>
        <div class="form-check form-switch mb-3 col-md-2" :class="{hidden:currentProduct?.productTypeId != 3 && currentProduct?.productTypeId != 5}">
          <input type="checkbox" id="vegan" v-model="currentProduct.vegan"  class="form-control form-check-input">
          <label class="form-check-label" for="vegan"><i class="fal fa-seedling"></i> vegan</label>
        </div>
        <div class="form-check form-switch mb-3 col-md-2" :class="{hidden:currentProduct?.productTypeId != 3 && currentProduct?.productTypeId != 5}">
          <input type="checkbox" id="glutenFree" v-model="currentProduct.glutenFree"  class="form-control form-check-input">
          <label class="form-check-label" for="glutenFree"><i class="fal fa-wheat-slash"></i> glutenFree</label>
        </div>
        <div class="languages container-fluid col-12">
          <div class="row container-fluid">
            <div class="language-tab mb-6 col-md-6 row" v-for="language in currentProduct?.languages">
              <h4>{{ language.localeName}}</h4>
              <div class="form-floating col-md-8">
                <input type="text" :id="'menu_name_' + language.id" v-model="currentProduct.languages[languages.findIndex(el => el.id == language.id)].infos.menuName" class="form-control" placeholder="nome in menu">
                <label class="form-label" :for="'menu_name_' + language.id">nome in menù</label>
              </div>
              <div class="form-floating col-md-4">
                <input type="text" :id="'short_name_' + language.id" v-model="currentProduct.languages[languages.findIndex(el => el.id == language.id)].infos.shortName" class="form-control" placeholder="nome in menu">
                <label class="form-label" :for="'short_name_' + language.id">nome pulsante</label>
              </div>
              <div class="form-floating">
                <textarea :id="'description_' + language.id" v-model="currentProduct.languages[languages.findIndex(el => el.id == language.id)].infos.description" class="form-control" placeholder="descrizione"></textarea>
                <label class="form-label" :for="'description_' + language.id">descrizione</label>
              </div>
            </div>
          </div>
        </div>



        <div class="mb-3 col-md-12 table-wrapper" :class="{hidden:currentProduct?.productTypeId!=2 && currentProduct?.productTypeId!=3 &&currentProduct?.productTypeId!=5 }">
          <table class="table table-striped">
            <caption>Distinta base <span :style="{display:'inline-block', width: '80px'}"  v-if="currentProduct.baseQuantity">per {{ currentProduct.unit.measure }} {{ currentProduct.baseQuantity / ( unitQuantities ? currentProduct.baseQuantity:1) }}</span>
              &nbsp;&nbsp;&nbsp;&nbsp;<input  type="checkbox" id="unitQuant" v-model="unitQuantities"> <label
                for="unitQuant">unitaria</label>
            </caption>
            <tbody>
              <tr v-for="component in currentProduct?.components" :key="component.id">
                <td>{{ component.name }}</td>
                <td>{{ (component.products_components.quantity / ( unitQuantities ? currentProduct.baseQuantity:1)).toFixed(3) }} {{ component.unit.measure }}</td>
                <td></td>
                <!--td>{{ component.id }}</td-->
              </tr>
            </tbody>
          </table>
        </div>
        <div class="mb-3 col-md-12">
          <FileUpload></FileUpload>
        </div>
        <div class="container">
          <div class="row">
            <div class="col-md-6">
              <button class="btn btn-secondary" type="button" @click="testCost"><i class="fal fa-dollar"></i> {{ cost }}</button>
            </div>
            <div class="col-md-3">
              <button class="btn btn-secondary" type="button" @click="close"><i class="fal fa-times"></i> annulla</button>
            </div>
            <div class="col-md-3">
              <button type="button" :disabled="currentProduct.name == ''" class="btn btn-primary" @click="save"><i class="fal fa-save"></i> salva</button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import {mapActions, mapState, mapMutations} from "vuex";
import FileUpload from "@/components/FileUpload";
import rgbHex from 'rgb-hex';


export default {
  name: "ProductsForm",
  components: {
    FileUpload
  },

  data() {
    return {
      unitQuantities: true,
      cost: null,
      oldProducts: [],
      /*product: {
        name: '',
        id: undefined,
        color: 'rgb(255,255,255)'
      },*/
      stateClass: 'hidden',
      languages: [
        {
          id: 1,
          name: 'italian',
          localeName: 'italiano',
          image: 'it.png',
        },
        {
          id: 2,
          name: 'english',
          localeName: 'inglese',
          image: 'en.png',
        }
      ]
    }
  },

  created() {
    window.addEventListener('keyup', this.keyPressed);
  },
  unmounted() {
    window.removeEventListener('keyupr', this.keyPressed);
  },

  mounted() {
    if (localStorage.getItem('productTypes') != null ) {
      this.setProductTypes( JSON.parse(localStorage.getItem('productTypes')));
      if (localStorage.getItem('units') != null ) {
        this.setUnits( JSON.parse(localStorage.getItem('units')));
        this.getCategories({});
        this.loadForm();
      }
      else {
        this.getUnits()
          .then(()=>{
            console.log("A");
            console.log(this.units);
            localStorage.setItem('units', JSON.stringify(this.units));
            this.getCategories({});
            this.loadForm();
          })
      }
    }
    else {
      this.getProductTypes()
      .then(()=>{
        localStorage.setItem('productTypes', JSON.stringify(this.productTypes));
        if (localStorage.getItem('units') != null ) {
          this.setUnits( JSON.parse(localStorage.getItem('units')));
          this.getCategories({});
          this.loadForm();
        }
        else {
          this.getUnits()
            .then(()=>{
              console.log("B");
              console.log(this.units);
              localStorage.setItem('units', JSON.stringify(this.units));
              this.getCategories({});
              this.loadForm();
            })
        }
      })
    }
  },

  methods: {
    ...mapActions({
      updateProductById: 'product/updateProductById',
      getProducts: 'product/getProducts',
      getProductTypes: 'product/getProductTypes',
      getProductCost: 'product/getCost',
      getUnits: 'unit/getUnits',
      getCategories: 'category/getCategories'
    }),
    ...mapMutations('core', ['setCurrentProduct','setCurrentLocation']),
    ...mapMutations('product', ['setProductTypes']),
    ...mapMutations('unit', ['setUnits']),

    loadForm() {
      //this.product = this.currentProduct;
    },

    testCost() {
      this.getProductCost(this.currentProduct.id).then(data=>{
        this.cost = data;
        console.log("cost:");
        console.log(data);
      });
    },

    save() {
      console.log(this.currentProduct);
      if (!this.currentProduct?.id) {
        alert("new");
        return;
      }
      else {
        console.log("about to save");
        this.currentProduct.productId = this.currentProduct.id;
        this.updateProductById(this.currentProduct).then((data) => {
          if (data.message == 'ok') {
            for (let i = 0; i < this.products.length; i++) {
              if (this.products[i].id == this.currentProduct.id)
                this.products[i] = this.currentProduct;
            }
            this.close();
          }
          //if
        });
      }
    },

    close() {
      this.setCurrentProduct(null);
    },

    keyPressed(event) {
      switch(event.code) {
        case 'Escape':
          this.close();
          break;
      }
      event.stopImmediatePropagation();
    },

    hexColor(color) {
      try {
        if (color.substr(0,1)=='#') return color;
        return '#' + rgbHex(color);

      }
      catch(e) {
        return 'transparent';
      }
    }
  },


  computed: {
    ...mapState('product', ['products','product','productTypes']),
    ...mapState('category', ['categories']),
    ...mapState('unit', ['units']),
    ...mapState('core', ['currentUser','currentProduct','currentLocation'])
  }

}
</script>

<style scoped>

</style>